/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { colors } from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import WebIcon from '@material-ui/icons/Web';

import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import StoreMallDirectoryRoundedIcon from '@material-ui/icons/StoreMallDirectoryRounded';
import ViewCarouselRoundedIcon from '@material-ui/icons/ViewCarouselRounded';

import CardGiftcardRoundedIcon from '@material-ui/icons/CardGiftcardRounded';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';

import NaturePeopleIcon from '@material-ui/icons/NaturePeople';

import FaceIcon from '@material-ui/icons/Face';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import EmojiPeopleRoundedIcon from '@material-ui/icons/EmojiPeopleRounded';

import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import MovieOutlinedIcon from '@material-ui/icons/MovieOutlined';

import AssessmentIcon from '@material-ui/icons/Assessment';
import CakeRoundedIcon from '@material-ui/icons/CakeRounded';

import MailOutlineIcon from '@material-ui/icons/MailOutline';

import Label from 'src/components/Label';

export default [
	{
		subheader: 'nav.data.default',
		items: [
			{
				id: 'overview',
				title: 'nav.data.overview',
				href: '/overview',
				icon: AppsIcon,
				label: () => <Label color={colors.teal.A200}>Live</Label>
			},
			{
				id: 'events',
				title: 'nav.event',
				href: '/events',
				icon: EventNoteIcon
			},
			{
				id: 'news',
				title: 'nav.news',
				href: '/news',
				icon: AnnouncementOutlinedIcon
			},
			{
				id: 'website',
				title: 'nav.website.default',
				href: '/website',
				icon: WebIcon,
				items: [
					{
						title: 'nav.website.general',
						href: '/website'
					},
					{
						title: 'nav.website.analytics',
						href: '/website/analytics'
					}
				]
			},
		]
	},
	{
		subheader: 'nav.shopping.default',
		items: [
			{
				id: 'shopping',
				title: 'nav.shopping.product.default',
				href: '/shopping',
				icon: LocalMallOutlinedIcon,
				items: [
					{
						title: 'nav.shopping.product.categories',
						href: '/product/categories'
					},
					{
						title: 'nav.shopping.product.products',
						href: '/products'
					}
				]
			},
			{
				id: 'member_orders',
				title: 'nav.shopping.member_orders',
				href: '/member/orders',
				icon: StoreMallDirectoryRoundedIcon,
			},
			{
				id: 'shopping_website',
				title: 'nav.shopping.website.default',
				href: '/shopping/website',
				icon: WebIcon,
				items: [
					{
						title: 'nav.shopping.website.general',
						href: '/shopping/website'
					},
					{
						title: 'nav.shopping.website.analytics',
						href: '/shopping/website/analytics'
					},
					{
						title: 'nav.shopping.website.articles',
						href: '/shopping/website/articles'
					}
				]
			},
			{
				id: 'shopping_banners',
				title: 'nav.shopping.banners',
				href: '/shopping/banners',
				icon: ViewCarouselRoundedIcon,
			},
			{
				id: 'shipping_articles',
				title: 'nav.shopping.articles',
				href: '/shopping/articles',
				icon: BookOutlinedIcon,
			},
		]
	},
	{
		subheader: 'nav.redeem.default',
		items: [
			{
				id: 'redeem',
				title: 'nav.redeem.default',
				href: '/redeem',
				icon: CardGiftcardRoundedIcon,
				items: [
					{
						title: 'nav.redeem.gifts',
						href: '/redeem/gifts'
					},
					{
						title: 'nav.redeem.histories',
						href: '/redeem/histories'
					},
				]
			},
			{
				id: 'redeem_settings',
				business_type: 'single',
				title: 'nav.redeem.settings',
				href: '/redeem/settings/general',
				icon: TuneRoundedIcon,
			}
		]
	},
	{
		subheader: 'nav.content.default',
		items: [
			{
				id: 'content_articles',
				title: 'nav.content.article.default',
				href: '/content/articles',
				icon: BookOutlinedIcon,
				items: [
					{
						title: 'nav.content.article.categories',
						href: '/content/article/categories'
					},
					{
						title: 'nav.content.article.contents',
						href: '/content/articles'
					}
				]
			},
			{
				id: 'content_videos',
				title: 'nav.content.video.default',
				href: '/content/videos',
				icon: MovieOutlinedIcon,
				items: [
					{
						title: 'nav.content.video.categories',
						href: '/content/video/categories'
					},
					{
						title: 'nav.content.video.contents',
						href: '/content/videos'
					}
				]
			},
		]
	},
	{
		subheader: 'nav.guest.default',
		items: [
			{
				id: 'guests',
				title: 'nav.guest.guests',
				href: '/guests',
				icon: NaturePeopleIcon
			},
			{
				id: 'guests_orders',
				title: 'nav.guest.orders',
				href: '/guests/orders',
				icon: NaturePeopleIcon
			},
			{
				id: 'guests_settings',
				title: 'nav.guest.settings',
				href: '/guest/settings',
				icon: WebIcon,
			},
		]
	},
	{
		subheader: 'nav.member.default',
		items: [
			{
				id: 'members',
				title: 'nav.member.member.default',
				href: '/members',
				icon: FaceIcon,
				items: [
					{
						title: 'nav.member.member.groups',
						href: '/member/groups'
					},
					{
						title: 'nav.member.member.members',
						href: '/members'
					},
				]
			},
			{
				id: 'qualified',
				title: 'nav.member.qualified',
				href: '/member/qualified',
				icon: RecentActorsOutlinedIcon,
			},
			{
				id: 'quota',
				title: 'nav.member.quota',
				href: '/member/quota',
				icon: EmojiPeopleRoundedIcon,
			},
			{
				id: 'member_settings',
				title: 'nav.member.settings',
				href: '/member/settings',
				icon: WebIcon,
			},
		]
	},
	{
		subheader: 'nav.kpi.default',
		items: [
			{
				id: 'kpi_achievements',
				title: 'nav.kpi.achievements',
				href: '/kpi/achievements',
				icon: AssessmentIcon
			},
			{
				id: 'kpi_leaders',
				title: 'nav.kpi.leaders',
				href: '/kpi/leaders',
				icon: CakeRoundedIcon
			}
		]
	},
	{
		subheader: 'nav.tool.default',
		items: [
			{
				id: 'email',
				title: 'nav.tool.email.default',
				href: '/email',
				icon: MailOutlineIcon,
				items: [
					// {
					// 	title: 'nav.tool.email.categories',
					// 	href: '/email/categories'
					// },
					{
						title: 'nav.tool.email.templates',
						href: '/email/templates'
					},
					{
						title: 'nav.tool.email.settings',
						href: '/email/settings'
					}
				]
			},
			{
				id: 'liff',
				title: 'nav.tool.liff.default',
				href: '/liff',
				icon: MailOutlineIcon,
				items: [
					{
						title: 'nav.tool.liff.categories',
						href: '/liff/categories'
					},
					{
						title: 'nav.tool.liff.contents',
						href: '/liff/contents'
					},
					// {
					// 	title: 'nav.tool.liff.settings',
					// 	href: '/liff/settings'
					// }
				]
			},
		]
	},
];
