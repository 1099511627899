import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
	content: {
	},
	message: {
		display: 'flex',
		alignItems: 'center'
	},
	icon: {
		marginRight: theme.spacing(2)
	}
}));

function SlideTransition(props) {
	return <Slide {...props} direction="down" />;
}

function InfoSnackbar({ message, open, onClose }) {
	const classes = useStyles();

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
			autoHideDuration={6000}
			onClose={onClose}
			open={open}
			TransitionComponent={SlideTransition}
		>
			<SnackbarContent
				className={classes.content}
				message={(
					<span className={classes.message}>
						<NotificationsActiveIcon className={classes.icon} />
						{message}
					</span>
				)}
				component="h6"
			/>
		</Snackbar>
	);
}

InfoSnackbar.propTypes = {
	message: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool
};

InfoSnackbar.defaultProps = {
	message: '',
	open: true,
	onClose: () => { }
};

export default InfoSnackbar;
