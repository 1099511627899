import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Grid
} from '@material-ui/core';

import TeamBoard from './TeamBoard';

const useStyles = makeStyles(() => ({
	root: {
	},
}));

function Member({ className, ...rest }) {
	const classes = useStyles();

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Grid
				alignItems="flex-start"
				container
				justify="space-between"
				spacing={3}
			>
				<Grid
					item
					md={12}
					xs={12}
				>
					<TeamBoard />
				</Grid>
			</Grid>
		</div>
	);
}

Member.propTypes = {
	className: PropTypes.string
};

export default Member;
