import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

// Example of user roles: ['GUEST', 'USER', 'ADMIN'];

function AuthGuard({ roles, children }) {
	const session = useSelector((state) => state.session);
	const history = useHistory();

	useEffect(() => {
		if (!session.loggedIn || !session.user) {
			history.push('/auth/login');
			return;
		}

		//	Check nav array from redux
		const { pathname } = history.location;
		const filteredNav = session.nav_menu.filter((nav) => pathname.includes(nav.href));
		// if (!roles.includes(session.user.role) || filteredNav.length === 0) {
		// 	history.replace('/errors/error-401');
		// }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, roles, session.loggedIn, session.user]);

	return <>{children}</>;
}

AuthGuard.propTypes = {
	children: PropTypes.node,
	roles: PropTypes.array.isRequired
};

export default AuthGuard;
