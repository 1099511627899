import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import Statistics from './Statistics';
// import Notifications from './Notifications';
// import Projects from './Projects';
// import Todos from './Todos';
import Lead from './Lead';
import Member from './Member';
import Product from './Product';
import Share from './Share';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(1.5),
		paddingBottom: theme.spacing(3)
	},
	coverContainer: {
		position: 'relative',
	},
	cover: {
		position: 'absolute',
		width: '100%',
		height: 520,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'top right'
	},
	summary: {
		position: 'relative',
		paddingRight: theme.spacing(3)
	},
	statistics: {
		marginTop: theme.spacing(3)
	},
	notifications: {
		marginTop: theme.spacing(6)
	},
	projects: {
		marginTop: theme.spacing(6)
	},
	todos: {
		marginTop: theme.spacing(6)
	}
}));

function Overview() {
	const classes = useStyles();

	return (
		<Page
			className={classes.root}
			title="Overview"
		>
			<Container
				maxWidth="lg"
			>
				{/* <div
					className={classes.coverContainer}
				>
					<div
						className={classes.cover}
						style={{ backgroundImage: 'url(\'/images/dashboard.svg\')' }}
					/>
				</div> */}
				<Header />
				
				<Grid
					container
					justify="space-between"
					spacing={5}
				>
					<Grid
						item
						md={12}
						xs={12}
					>
						<Statistics className={classes.statistics} />
					</Grid>
					<Grid
						item
						md={12}
						xs={12}
					>
						<Share />
					</Grid>
					<Grid
						item
						md={12}
						xs={12}
					>
						<Product />
					</Grid>
					<Grid
						item
						md={12}
						xs={12}
					>
						<Lead />
					</Grid>
					<Grid
						item
						md={12}
						xs={12}
					>
						<Member />
					</Grid>
				</Grid>

				{/* <div
					className={classes.summary}
				>
					<Statistics className={classes.statistics} />
					<Notifications className={classes.notifications} />
					<Projects className={classes.projects} />
					<Todos className={classes.todos} />
				</div> */}
			</Container>
		</Page>
	);
}

export default Overview;
