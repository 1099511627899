import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
	Button,
	Card,
	CardHeader,
	CardContent,
	Typography
} from '@material-ui/core';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import Chart from './Chart';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {},
	content: {},
	title: {
		padding: theme.spacing(2, 0)
	},
	seeAllButton: {
		marginTop: 18
	},
	inner: {
		height: 335,
		minWidth: 500
	},
	chart: {
		height: '100%'
	}
}));

function LeadChart({ className, data, ...rest }) {
	const classes = useStyles();

	// const initialData = {
	// 	data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	// 	labels: [
	// 		'Jan',
	// 		'Feb',
	// 		'Mar',
	// 		'Apr',
	// 		'May',
	// 		'Jun',
	// 		'Jul',
	// 		'Aug',
	// 		'Sep',
	// 		'Oct',
	// 		'Nov',
	// 		'Dec'
	// 	]
	// };
	const initialData = {
		data: [
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
			0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
			0, 0
		],
		labels: [
			'1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
			'11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
			'21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
			'31'
		]
	};
	const [object, setObject] = useState(initialData);

	//	Functions

	useEffect(() => {
		const newObject = { ...object };
		if (data) {
			data.map((item) => {
				newObject.data[item.day - 1] = item.count;
				return item;
			});
		}

		setObject(newObject);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardHeader
				title={(
					<Typography
						className={classes.title}
						variant="h4"
					>
						<strong>{locale.t('overview.members.chart_title')}</strong>
					</Typography>
				)}
				action={(
					<Button
						className={classes.seeAllButton}
						component={RouterLink}
						to="/members"
						variant="outlined"
						endIcon={<ChevronRightRoundedIcon />}
					>
						{locale.t('btn.see_all')}
					</Button>
				)}
			/>
			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Chart
							className={classes.chart}
							data={object.data}
							labels={object.labels}
						/>
					</div>
				</PerfectScrollbar>
			</CardContent>
		</Card>
	);
}

LeadChart.propTypes = {
	className: PropTypes.string,
	data: PropTypes.array
};

export default LeadChart;
