export default {
	app_name: 'Dhair Thailand',
	btn: {
		yes: 'ใช่',
		no: 'ไม่ใช่',
		cancel: 'ยกเลิก',
		discard_changes: 'ยกเลิกการเปลี่ยนแปลง',
		save: 'บันทึก',
		close: 'ปิด',
		clear: 'ล้าง',
		applyFilters: 'บันทึกตัวกรอง',
		search: 'ค้นหา',
		delete: 'ลบ',
		signout: 'ออกจากระบบ',
		next: 'ถัดไป',
		previous: 'ก่อนหน้า',
		add: 'เพิ่ม'
	},
	dialog: {
		title: 'ข้อความ',
		discard_changes_message: 'ยกเลิการเปลี่ยนแปลงที่เกิดขึ้น?',
		delete_data_message: 'คุณแน่ใจที่จะลบข้อมูลใช่หรือไม่?',
	},
	badge: {
		status: {
			active: 'เปิดสาธารณะ',
			inactive: 'ซ่อนการมองเห็น',
		}
	},
	nav: {
		data: {
			default: 'การจัดการข้อมูล',
			overview: 'Overview'
		},
		event: 'กิจกรรม',
		news: 'ข่าวและโปรโมชัน',
		website: {
			default: 'ตั้งค่าเว็บไซต์หลัก',
			general: 'ข้อมูลทั่วไป',
			analytics: 'Analytics'
		},
		shopping: {
			default: 'ซื้อขายสินค้า',
			product: {
				default: 'สินค้า',
				categories: 'หมวดหมู่',
				products: 'สินค้า'
			},
			member_orders: 'รายการสั่งซื้อสมาชิก',
			website: {
				default: 'ตั้งค่าเว็บไซต์สินค้า',
				general: 'ข้อมูลทั่วไป',
				analytics: 'Analytics',
				articles: 'บทความ'
			},
			banners: 'แบนเนอร์เว็บไซต์',
			articles: 'บทความเว็บไซต์'
		},
		redeem: {
			default: 'แลกของรางวัล',
			gifts: 'รายการของรางวัล',
			histories: 'ประวัติแลกรางวัล',
			settings: 'ตั้งค่าแลกรางวัล',
		},
		content: {
			default: 'E-Learning',
			article: {
				default: 'บทความ',
				categories: 'หมวดหมู่',
				contents: 'คอนเทนต์'
			},
			video: {
				default: 'วิดีโอ',
				categories: 'หมวดหมู่',
				contents: 'คอนเทนต์'
			},
		},
		guest: {
			default: 'การจัดการผู้มุ่งหวัง',
			guests: 'รายการผู้มุ่งหวัง',
			orders: 'รายการสั่งซื้อ',
			settings: 'ตั้งค่าเว็บไซต์และผู้มุ่งหวัง'
		},
		member: {
			default: 'การจัดการตัวแทน',
			member: {
				default: 'จัดการสมาชิก',
				groups: 'ระดับ',
				members: 'สมาชิก',
			},
			qualified: 'ผู้ประสบความสำเร็จ',
			quota: 'สุ่มรายชื่อ',
			settings: 'ตั้งค่าเว็บไซต์และสมาชิก'
		},
		kpi: {
			default: 'วัดผล KPI',
			achievements: 'เป้าหมาย',
			leaders: 'รายการผู้พิชิตเป้าหมาย',
		},
		tool: {
			default: 'เครื่องมืออื่นๆ',
			email: {
				default: 'Email Marketing',
				categories: 'หมวดหมู่',
				templates: 'เทมเพลต',
				settings: 'ตั้งค่า'
			},
			liff: {
				default: 'Line Share',
				categories: 'หมวดหมู่',
				contents: 'Content',
				settings: 'ตั้งค่า'
			},
		}
	},
	login: {
		title: 'Online Marketing Platform',
		header: 'เข้าสู่ระบบ',
		sub_header: 'เริ่มใช้งานแพลตฟอร์มของเรา',
		email: 'อีเมล',
		password: 'รหัสผ่าน',
		login: 'เข้าสู่ระบบ',
		register: 'ไม่มีบัญชีใช้งาน?',
		validator: {
			email: '^กรุณากรอกอีเมล์',
			email_invalid: '^อีเมล์ไม่ถูกต้อง',
			password: '^กรุณากรอกรหัสผ่าน'
		},
		login_fail: 'อีเมล์หรือรหัสผ่านไม่ถูกต้อง, ลองอีกครั้ง!'
	},
	topbar: {
		signout: {
			title: 'แน่ใจหรือไม่?',
			message: 'คุณจะไม่สามารถจัดการข้อมูลบนเครื่องนี้ได้แล้ว กรุณาตรวจสอบการบันทึกข้อมูล'
		}
	},
	result_table: {
		filter_action: 'แสดงตัวกรอง',
		hint: ' รายการที่พบ หน้า ',
		hint2: ' จาก ',
		rows_per_page: 'แถวต่อหน้า:',
		page_hint: 'จาก',
		page_hint2: 'มากกว่า ',
		loading: 'กำลังเชื่อมต่อข้อมูล...'
	},
	notifications: {
		title: 'แจ้งเตือน'
	},
	order: {
		status: {
			cancelled: 'ยกเลิกคำสั่งซื้อ',
			new: 'ได้รับคำสั่งซื้อ',
			paid: 'จ่ายเงินแล้ว',
			confirm_paid: 'ยืนยันการจ่ายแล้ว',
			ready_to_ship: 'พร้อมจัดส่ง',
			shipped: 'จัดส่งแล้ว',
			delivered: 'ส่งถึงปลายทางแล้ว',
		},
	},
	overview: {
		stats: {
			registered_member: 'ลงทะเบียนเว็บโปรโมท',
			upgraded_member: 'สนใจอัพเกรด',
			team_member: 'ทีมงานของคุณ',
			order: 'คำสั่งซื้อ'
		},
		share: {
			liff: {
				chart_title: 'สถิติแชร์ผ่านไลน์เดือนนี้',
				chart_unit: 'จำนวน %{label} ครั้ง',
			},
			page: {
				chart_title: 'สถิติแชร์เซลเพจ',
				chart_unit: 'จำนวน %{label} ครั้ง',
			}
		},
		members: {
			chart_title: 'สถิติสมาชิกใหม่ในเดือนนี้',
			chart_unit: 'จำนวน %{label} คน',
			all_title: 'จำนวนสมาชิกทั้งหมด',
			all_unit: 'ตั้งแต่คุณเริ่มต้นใช้งาน',
			approved_title: 'จำนวนผู้สม้ครเดือนนี้',
			approved_unit: 'ที่เข้าเรียนรู้ในระบบได้',
		},
		member: {
			leader: 'Leadership / ผู้สำเร็จประจำเดือน',
			team: 'นักธุรกิจเข้าระบบล่าสุด',
		},
		total_income: {
			title: 'ยอดขายทั้งหมด',
			subheader: 'รวมทั้งหมดตั้งแต่เริ่มใช้งานระบบ',
			report_caption: 'ดูรายการสั่งซื้อทั้งหมดจากไหน?'
		},
		monthly_income: {
			title: 'ยอดขายในเดือนนี้',
			subheader: 'ยอดขายรวมทั้งหมด',
			target_complete: 'คุณถึงเป้าหมายของเดือนนี้แล้ว!',
			report_caption: 'ดูรายการยอดขายเดือนนี้จากไหน?'
		}
	},
	events: {
		title: 'รายการอีเวนท์',
		header: 'ปฏิทินอีเวนท์',
		action: 'เพิ่มรายการ',
		toolbar: {
			prev: 'ก่อนหน้า',
			today: 'วันนี้',
			next: 'ต่อไป',
			views: {
				month: 'เดือน',
				week: 'สัปดาห์',
				day: 'วัน',
				agenda: 'กำหนดการ'
			}
		},
		create_success: 'สร้างข้อมูลอีเวนท์ใหม่เรียบร้อยแล้ว',
		edit_success: 'บันทึกข้อมูลอีเวนท์ใหม่เรียบร้อยแล้ว',
		delete_success: 'ลบข้อมูลอีเวนท์ใหม่เรียบร้อยแล้ว',
		create: {
			header: 'สร้างข้อมูลอีเวนท์',
		},
		edit: {
			header: 'แก้ไขข้อมูลอีเวนท์',
		},
		detail: {
			title: 'หัวข้อ',
			title_hint: 'จำกัดตัวอักษร %{count}/100',
			description: 'รายละเอียด',
			description_hint: 'จำกัดตัวอักษร %{count}/250',
			meeting_url: 'ลิงค์สำหรับประชุม',
			tags: 'กลุ่มแท็ก',
			tags_hint: 'เลือกสีสำหรับการจัดกลุ่มอีเวนท์',
			all_day: 'ตลอดวัน',
			all_day_hint: 'เปิด-ปิด เพื่อกำหนดกรณ๊อีเวนท์จัดงานตลอดทั้งวัน',
			start_date: 'วันที่เริ่ม',
			start_time: 'เวลา',
			end_date: 'ถึงวันที่',
			end_time: 'เวลา',
			validator: {
				title: '^กรุณากรอกหัวข้อ',
				description: '^กรุณากรอกรายละเอียด',
			},
		},
	},
	news: {
		title: 'รายการข่าวและโปรโมชัน',
		header: 'รายการข่าวและโปรโมชัน',
		action: 'เพิ่มรายการ',
		search_placeholder: 'หัวข้อข่าวและโปรโมชัน',
		no_data: 'ไม่พบข้อมูลรายการข่าวและโปรโมชัน',
		result_title: 'รายการข่าวและโปรโมชันทั้งหมด',
		item_col: 'หัวข้อข่าวและโปรโมชัน',
		item_col2: 'สถานะ',
		item_col3: 'Actions',
		delete_success: 'ลบข้อมูลข่าวและโปรโมชันเรียบร้อยแล้ว',
		create: {
			header: 'สร้างข้อมูลข่าวและโปรโมชัน',
			save_fail: 'ไม่สามารถสร้างข้อมูลข่าวและโปรโมชันได้ กรุณาติดต่อผู้ดูแลระบบ'
		},
		detail: {
			title: 'แก้ไขรายการข่าวและโปรโมชัน',
			header: 'จัดการข้อมูลข่าวและโปรโมชัน',
			form_header: 'แก้ไขข่าวและโปรโมชัน',
			form_sub_header: 'รายละเอียดข่าวและโปรโมชันสำหรับภายในระบบผู้ใช้',
			title_: 'หัวข้อข่าวและโปรโมชัน',
			title_hint: 'จำกัดตัวอักษร %{count}/100',
			description: 'รายละเอียดแบบย่อ',
			description_hint: 'จำกัดตัวอักษร %{count}/250',
			is_enabled: 'เปิดเผยต่อสาธารณะ',
			is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงบทความต่อสมาชิกที่ใช้ระบบ',
			content: 'เนื้อหาข่าวและโปรโมชัน',
			content_hint: 'กรอกรายละเอียดที่เกี่ยวข้องกับเนื้อหา...',
			save_success: 'บันทึกข้อมูลข่าวและโปรโมชันใหม่เรียบร้อยแล้ว',
			image: {
				header: 'อัพโหลดภาพปก',
				hint: 'คลิกหรือลากไฟล์มาตรงนี้',
				info: 'ขนาดภาพแนะนำ 480x480'
			},
			validator: {
				title: '^กรุณากรอกหัวข้อข่าวและโปรโมชัน',
				description: '^กรุณากรอกรายละเอียดแบบย่อ',
			},
			other_actions: {
				header: 'Other Actions',
				delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
				btn_delete: 'ลบข้อมูลบทความ'
			}
		}
	},
	website: {
		title: 'ตั้งค่าเว็บไซต์หลัก',
		header: 'โปรโมทเว็บไซต์หลัก',
		tab: {
			title: 'ข้อมูลทั่วไป',
			image: {
				header: 'โลโก้และไอคอน',
				logo: 'โลโก้บนหน้าเว็บไซต์',
				logo_hint: 'คลิกหรือลากไฟล์ตรงนี้เพื่ออัพโหลดโลโก้',
				logo_info: 'ขนาดความสูงภาพแนะนำ 64px (ความกว้างขึ้นอยู่กับสัดส่วนภาพ)',
				favicon: 'Favicon',
				favicon_desc: 'ไอคอนสำหรับแสดงผลบน Web browser และ Bookmark, ขนาดภาพแนะนำ 64x64',
				favicon_hint: 'คลิกหรือลากไฟล์ตรงนี้เพื่ออัพโหลด Favicon',
				favicon_info: 'ขนาดภาพแนะนำ 64x64',
			},
			general: {
				header: 'General',
				site_title: 'Site title',
				site_title_hint: 'จำกัดตัวอักษร %{count}/50',
				tagline: 'Tagline',
				tagline_hint: 'คำอธิบายสั้นๆ สำหรับเว็บไซต์, จำกัดตัวอักษร %{count}/100',
				email: 'อีเมล',
				phone: 'เบอร์โทรศัพท์',
				address: 'ที่อยู่',
				validator: {
					site_title: '^กรุณากรอก Site title',
					tagline: '^กรุณากรอก Tag line',
					email: '^กรุณากรอกอีเมล',
					phone: '^กรุณากรอกเบอร์โทรศัพท์',
					address: '^กรุณากรอกที่อยู่',
				},
			},
			social: {
				header: 'Social Network',
				line: 'Line Id',
				line_placeholder: '@line',
				facebook: 'Facebook',
				facebook_placeholder: 'www.facebook.com',
				instagram: 'Instagram',
				instagram_placeholder: 'www.instagram.com',
			},
			save_success: 'บันทึกข้อมูลเว็บไซต์หลักเรียบร้อยแล้ว',
		},
		tab2: {
			title: 'Analytics',
			seo_title: 'Google SEO',
			seo_subheader: 'เพิ่มจำนวและคุณภาพ Traffic การเข้าถึงเว็บไซต์ของคุณผ่าน Organic search engine',
			meta_title: 'Title',
			meta_title_hint: 'จำกัดตัวอักษร %{count}/60',
			meta_desc: 'Description',
			meta_desc_hint: 'จำกัดตัวอักษร %{count}/160',
			meta_keyword: 'Keyword',
			meta_keyword_hint: 'จำกัดตัวอักษร %{count}/255',
			meta_keyword_placeholder: 'Enter keyword and then press "Enter"',
			pixel_title: 'Facebook Pixel',
			pixel_subheader: 'ชุดสคริปต์ที่เก็บข้อมูลกิจกรรมบนเว็บไซต์ เป็น Analytics Tools ที่ช่วยให้ Facebook บันทึกข้อมูลที่เกิดขึ้นบนเว็บไซต์ได้',
			og_title: 'Title',
			og_title_hint: 'จำกัดตัวอักษร %{count}/95',
			og_desc: 'Description',
			og_desc_hint: 'จำกัดตัวอักษร %{count}/155',
			og_image: 'ภาพประกอบ',
			og_image_info: 'ขนาดภาพแนะนำ 1200x630',
			validator: {
				meta_title: '^กรุณากรอก Title',
				meta_desc: '^กรุณากรอก Description',
				og_title: '^กรุณากรอก Title',
				og_desc: '^กรุณากรอก Description',
			},
			save_success: 'บันทึกข้อมูล Analytics เรียบร้อยแล้ว',
		}
	},
	content: {
		article: {
			categories: {
				title: 'รายการหมวดหมู่',
				header: 'รายการหมวดหมู่',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่อหมวดหมู่',
				result_title: 'รายการหมวดหมู่ทั้งหมด',
				no_data: 'ไม่พบข้อมูลรายการหมวดหมู่',
				item_col: 'ชื่อหมวดหมู่',
				item_col2: 'สถานะ',
				item_col3: 'Actions',
				delete_success: 'ลบข้อมูลหมวดหมู่เรียบร้อยแล้ว',
				create: {
					header: 'สร้างข้อมูลหมวดหมู่',
					save_fail: 'ไม่สามารถสร้างข้อมูลหมวดหมู่ได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการหมวดหมู่',
					header: 'จัดการข้อมูลหมวดหมู่',
					form_header: 'แก้ไขหมวดหมู่',
					form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับบทความและวิดีโอการสอนภายใน Knowledge base ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
					name: 'ชื่อหมวดหมู่',
					name_hint: 'จำกัดตัวอักษร %{count}/100',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงหมวดหมู่ต่อสมาชิกที่ใช้ระบบ',
					save_success: 'บันทึกการข้อมูลหมวดหมู่ใหม่เรียบร้อยแล้ว',
					validator: {
						name: '^กรุณากรอกชื่อหมวดหมู่',
					},
					features: {
						header: 'Features ของสมาชิก',
						sub_header: 'ตั้งค่าการแสดงผลกับ Member group',
						group_id: 'Member group'
					},
					guest_features: {
						header: 'Features ของผู้มุ่งหวัง',
						allow_guest: 'อนุญาตให้ผู้มุ่งหวังเห็นได้',
						special_for_guest: 'หัวข้อ Why สำหรับผู้มุ่งหวัง'
					},
					image: {
						header: 'อัพโหลดภาพปก',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 480x480'
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลหมวดหมู่'
					}
				}
			},
			contents: {
				title: 'รายการบทความ',
				header: 'รายการบทความ',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่อบทความ',
				filter: {
					section: 'Info',
					category_id: 'หมวดหมู่',
					all_category_id: 'หมวดหมู่ทั้งหมด',
				},
				result_title: 'รายการบทความทั้งหมด',
				no_data: 'ไม่พบข้อมูลรายการบทความ',
				item_col: 'ชื่อบทความ',
				item_col_ep: 'บทที่ %{ep}',
				item_col2: 'หมวดหมู่',
				item_col3: 'สถานะ',
				item_col4: 'Actions',
				delete_success: 'ลบข้อมูลบทความเรียบร้อยแล้ว',
				create: {
					header: 'สร้างข้อมูลบทความ',
					save_fail: 'ไม่สามารถสร้างข้อมูลบทความได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการบทความ',
					header: 'จัดการข้อมูลบทความ',
					form_header: 'แก้ไขบทความ',
					form_sub_header: 'รายละเอียดบทความสำหรับภายในระบบผู้ใช้',
					category_id: 'หมวดหมู่บทความ',
					category_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					title_: 'ชื่อบทความ',
					title_hint: 'จำกัดตัวอักษร %{count}/250',
					description: 'รายละเอียดแบบย่อ',
					description_hint: 'จำกัดตัวอักษร %{count}/250',
					seq_no: 'ลำดับบทความ',
					tags: 'ติดแท็ก',
					tags_hint: 'จำกัดตัวอักษร %{count}/255',
					tags_placeholder: 'Enter keyword and then press "Enter"',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงบทความต่อสมาชิกที่ใช้ระบบ',
					content: 'เนื้อหาบทความ',
					content_hint: 'กรอกรายละเอียดที่เกี่ยวข้องกับเนื้อหา...',
					save_success: 'บันทึกข้อมูลบทความใหม่เรียบร้อยแล้ว',
					image: {
						header: 'อัพโหลดภาพปก',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 480x480'
					},
					validator: {
						title: '^กรุณากรอกชื่อบทความ',
						description: '^กรุณากรอกรายละเอียดแบบย่อ',
						seq_no: '^กรุณากรอกลำดับบทความ',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลบทความ'
					}
				}
			},
		},
		video: {
			categories: {
				title: 'รายการหมวดหมู่',
				header: 'รายการหมวดหมู่',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่อหมวดหมู่',
				result_title: 'รายการหมวดหมู่ทั้งหมด',
				no_data: 'ไม่พบข้อมูลรายการหมวดหมู่',
				item_col: 'ชื่อหมวดหมู่',
				item_col2: 'สถานะ',
				item_col3: 'Actions',
				delete_success: 'ลบข้อมูลหมวดหมู่เรียบร้อยแล้ว',
				create: {
					header: 'สร้างข้อมูลหมวดหมู่',
					save_fail: 'ไม่สามารถสร้างข้อมูลหมวดหมู่ได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการหมวดหมู่',
					header: 'จัดการข้อมูลหมวดหมู่',
					form_header: 'แก้ไขหมวดหมู่',
					form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับบทความและวิดีโอการสอนภายใน Knowledge base ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
					name: 'ชื่อหมวดหมู่',
					name_hint: 'จำกัดตัวอักษร %{count}/100',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงหมวดหมู่ต่อสมาชิกที่ใช้ระบบ',
					save_success: 'บันทึกการข้อมูลหมวดหมู่ใหม่เรียบร้อยแล้ว',
					validator: {
						name: '^กรุณากรอกชื่อหมวดหมู่',
					},
					features: {
						header: 'Features',
						sub_header: 'ตั้งค่าการแสดงผลกับ Member group',
						group_id: 'Member group'
					},
					guest_features: {
						header: 'Features ของผู้มุ่งหวัง',
						allow_guest: 'อนุญาตให้ผู้มุ่งหวังเห็นได้',
						special_for_guest: 'หัวข้อ Why สำหรับผู้มุ่งหวัง'
					},
					image: {
						header: 'อัพโหลดภาพปก',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 480x480'
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลหมวดหมู่'
					}
				}
			},
			contents: {
				title: 'รายการวิดีโอ',
				header: 'รายการวิดีโอ',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่อวิดีโอ',
				filter: {
					section: 'Info',
					category_id: 'หมวดหมู่',
					all_category_id: 'หมวดหมู่ทั้งหมด',
				},
				result_title: 'รายการวิดีโอทั้งหมด',
				no_data: 'ไม่พบข้อมูลรายการวิดีโอ',
				item_col: 'ชื่อวิดีโอ',
				item_col_ep: 'EP.%{ep}',
				item_col2: 'หมวดหมู่',
				item_col3: 'สถานะ',
				item_col4: 'Actions',
				delete_success: 'ลบข้อมูลวิดีโอเรียบร้อยแล้ว',
				create: {
					header: 'สร้างข้อมูลวิดีโอ',
					save_fail: 'ไม่สามารถสร้างข้อมูลวิดีโอได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการวิดีโอ',
					header: 'จัดการข้อมูลวิดีโอ',
					form_header: 'แก้ไขวิดีโอ',
					form_sub_header: 'รายละเอียดวิดีโอสำหรับภายในระบบผู้ใช้',
					category_id: 'หมวดหมู่วิดีโอ',
					category_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					title_: 'ชื่อวิดีโอ',
					title_hint: 'จำกัดตัวอักษร %{count}/250',
					description: 'รายละเอียด',
					description_hint: 'จำกัดตัวอักษร %{count}/250',
					seq_no: 'ลำดับวิดีโอ (EP)',
					tags: 'ติดแท็ก',
					tags_hint: 'จำกัดตัวอักษร %{count}/255',
					tags_placeholder: 'Enter keyword and then press "Enter"',
					media_url: 'ลิงค์วิดีโอออนไลน์',
					media_url_hint: 'รองรับลิงค์วิดีโอจาก YouTube, Vimeo',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงวิดีโอต่อสมาชิกที่ใช้ระบบ',
					save_success: 'บันทึกการข้อมูลวิดีโอใหม่เรียบร้อยแล้ว',
					image: {
						header: 'อัพโหลดภาพปก',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 480x480'
					},
					validator: {
						title: '^กรุณากรอกชื่อวิดีโอ',
						description: '^กรุณากรอกรายละเอียด',
						seq_no: '^กรุณากรอกลำดับวิดีโอ',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลวิดีโอ'
					}
				}
			},
		}
	},
	shopping: {
		categories: {
			title: 'รายการหมวดหมู่',
			header: 'รายการหมวดหมู่',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อหมวดหมู่',
			no_data: 'ไม่พบข้อมูลรายการรายการหมวดหมู่',
			result_title: 'รายการหมวดหมู่ทั้งหมด',
			item_col: 'ชื่อหมวดหมู่',
			item_col2: 'สถานะ',
			item_col3: 'Actions',
			delete_success: 'ลบข้อมูลหมวดหมู่เรียบร้อยแล้ว',
			create: {
				header: 'สร้างข้อมูลหมวดหมู่',
				save_fail: 'ไม่สามารถสร้างข้อมูลหมวดหมู่ได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการหมวดหมู่',
				header: 'จัดการข้อมูลหมวดหมู่',
				status_active: 'เปิดสาธารณะ',
				status_inactive: 'ซ่อนการมองเห็น',
				form_header: 'แก้ไขหมวดหมู่',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับสินค้าในเว็บไซต์สินค้า ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
				name: 'ชื่อหมวดหมู่',
				name_hint: 'จำกัดตัวอักษร %{count}/100',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงหมวดหมู่ต่อสมาชิกที่ใช้ระบบ',
				save_success: 'บันทึกการข้อมูลหมวดหมู่ใหม่เรียบร้อยแล้ว',
				validator: {
					name: '^กรุณากรอกชื่อหมวดหมู่',
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลหมวดหมู่'
				}
			}
		},
		products: {
			title: 'รายการสินค้า',
			header: 'รายการสินค้า',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อสินค้า',
			no_data: 'ไม่พบข้อมูลรายการสินค้า',
			result_title: 'รายการสินค้าทั้งหมด',
			item_col: 'ชื่อสินค้า',
			item_col2: 'หมวดหมู่',
			item_col3: 'สถานะ',
			item_col4: 'Actions',
			delete_success: 'ลบข้อมูลสินค้าเรียบร้อยแล้ว',
			create: {
				header: 'สร้างข้อมูลสินค้า',
				save_fail: 'ไม่สามารถสร้างข้อมูลสินค้าได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการสินค้า',
				header: 'จัดการข้อมูลสินค้า',
				status_active: 'เปิดสาธารณะ',
				status_inactive: 'ซ่อนการมองเห็น',
				form_header: 'แก้ไขสินค้า',
				form_sub_header: 'รายละเอียดสินค้าสำหรับเว็บไซต์สินค้า',
				category_id: 'หมวดหมู่สินค้า',
				category_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				name: 'ชื่อสินค้า',
				name_hint: 'จำกัดตัวอักษร %{count}/250',
				short_desc: 'รายละเอียดแบบย่อ',
				short_desc_hint: 'จำกัดตัวอักษร %{count}/250',
				description: 'รายละเอียดแบบเต็ม',
				price: 'ราคาเต็ม',
				sale_price: 'ราคาขาย',
				sale_price_rate: 'กำหนดช่วงราคาขาย',
				sale_price_rate_hint: 'เพื่อให้ตัวแทนสามารถนำไปใช้อ้างอิงในการตั้งราคาได้',
				min_sale_price: 'ราคาขายต่ำสุด',
				max_sale_price: 'ราคาขายสูงสุด',
				point: 'คะแนนต่อชิ้น',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงสินค้านี้ต่อผู้ใช้ระบบ โดยจะมีผลภายใน 24 ชม.',
				description_hint: 'บอกรายละเอียดเกี่ยวกับสินค้าของคุณ...',
				save_success: 'บันทึกการข้อมูลสินค้าเรียบร้อยแล้ว',
				validator: {
					name: '^กรุณากรอกชื่อสินค้า',
					short_desc: '^กรุณากรอกรายละเอียดแบบย่อ',
					price: '^กรุณากรอกราคาเต็ม',
					sale_price: '^กรุณากรอกราคาขาย',
					point: '^กรุณากรอกคะแนนต่อชิ้น'
				},
				images: {
					header: 'ภาพประกอบสินค้า (%{count}/%{maximum})',
					sub_header: 'ขนาดภาพแนะนำ 800x800 และภาพที่ 1 จะกลายเป็นภาพหลักของสินค้า',
				},
				rates: {
					header: 'กำหนดราคาและจำนวนสินค้าสำหรับตัวแทน',
					price: 'ราคาต่อชิ้น',
					start: 'จำนวนระหว่าง',
					end: 'ถึง',
					no_data: 'กรุณาเพิ่มรายการค่าส่งตามรายการสินค้า'
				},
				features: {
					header: 'Features',
					sub_header: 'ตั้งค่าการแสดงผลข้อมูลพิเศษเพิ่มเติม',
					is_new: 'สินค้าใหม่',
					is_top: 'สินค้าขายดี'
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลสินค้า'
				}
			}
		},
		member_orders: {
			title: 'รายการคำสั่งซื้อสมาชิก',
			header: 'รายการคำสั่งซื้อสมาชิก',
			search_placeholder: 'หมายเลขคำสั่งซื้อ, ชื่อสมาชิก',
			result_title: 'รายการคำสั่งซื้อทั้งหมด',
			item_col: 'หมายเลขคำสั่งซื้อ',
			item_col_rejected: 'ไม่อนุมัติ',
			item_col_waiting: 'รอการตรวจสอบ',
			item_col_approved: 'อนุมัติแล้ว',
			item_col2: 'ยอดรวมทั้งหมด',
			item_col3: 'วันที่สั่งซื้อ',
			item_col4: 'Action',
			no_data: 'ไม่พบข้อมูลรายการคำสั่งซื้อสมาชิก',
			detail: {
				title: 'คำสั่งซื้อสมาชิก',
				header: 'จัดการข้อมูลคำสั่งซ์้อสมาชิก',
				reject_aciton: 'ไม่อนุมัติ',
				approve_aciton: 'บันทึกอนุมัติ',
				form_header: 'รายการคำสั่งซื้อสมาชิก',
				form_sub_header: 'รายละเอียดสินค้าและหลักฐานการชำระเงิน',
				total_price: 'ยอดรวมทั้งสิ้น',
				total_point: 'คะแนนสะสม',
				item: {
					unit_price: 'ราคาต่อชิ้น',
					point: 'คะแนนต่อชิ้น',
					qty: 'จำนวน',
					total: 'ยอดรวม'
				},
				save_success: 'บันทึกข้อมูลรายการคำสั่งซื้อสมาชิกเรียบร้อยแล้ว',
				image: {
					header: 'ภาพหลักฐานชำระเงิน',
				},
			}
		},
		website: {
			title: 'ตั้งค่า',
			header: 'โปรโมทเว็บไซต์หลัก',
			tab: {
				title: 'ข้อมูลทั่วไป',
				image: {
					header: 'โลโก้และไอคอน',
					logo: 'โลโก้บนหน้าเว็บไซต์',
					logo_hint: 'คลิกหรือลากไฟล์ตรงนี้เพื่ออัพโหลดโลโก้',
					logo_info: 'ขนาดความสูงภาพแนะนำ 64px (ความกว้างขึ้นอยู่กับสัดส่วนภาพ)',
					favicon: 'Favicon',
					favicon_desc: 'ไอคอนสำหรับแสดงผลบน Web browser และ Bookmark, ขนาดภาพแนะนำ 64x64',
					favicon_hint: 'คลิกหรือลากไฟล์ตรงนี้เพื่ออัพโหลด Favicon',
					favicon_info: 'ขนาดภาพแนะนำ 64x64',
				},
				general: {
					header: 'General',
					site_title: 'Site title',
					site_title_hint: 'จำกัดตัวอักษร %{count}/50',
					tagline: 'Tagline',
					tagline_hint: 'คำอธิบายสั้นๆ สำหรับเว็บไซต์, จำกัดตัวอักษร %{count}/100',
					email: 'อีเมล',
					phone: 'เบอร์โทรศัพท์',
					address: 'ที่อยู่',
					lat: 'พิกัดละติจูด',
					lng: 'ลองจิจูด',
					validator: {
						site_title: '^กรุณากรอก Site title',
						tagline: '^กรุณากรอก Tag line',
						email: '^กรุณากรอกอีเมล',
						phone: '^กรุณากรอกเบอร์โทรศัพท์',
						address: '^กรุณากรอกที่อยู่',
					},
				},
				social: {
					header: 'Social Network',
					line: 'Line Id',
					line_placeholder: '@line',
					facebook: 'Facebook',
					facebook_placeholder: 'www.facebook.com',
					instagram: 'Instagram',
					instagram_placeholder: 'www.instagram.com',
				},
				save_success: 'บันทึกข้อมูลเว็บไซต์หลักเรียบร้อยแล้ว',
			},
			tab2: {
				title: 'Analytics',
				seo_title: 'Google SEO',
				seo_subheader: 'เพิ่มจำนวและคุณภาพ Traffic การเข้าถึงเว็บไซต์ของคุณผ่าน Organic search engine',
				meta_title: 'Title',
				meta_title_hint: 'จำกัดตัวอักษร %{count}/60',
				meta_desc: 'Description',
				meta_desc_hint: 'จำกัดตัวอักษร %{count}/160',
				meta_keyword: 'Keyword',
				meta_keyword_hint: 'จำกัดตัวอักษร %{count}/255',
				meta_keyword_placeholder: 'Enter keyword and then press "Enter"',
				pixel_title: 'Facebook Pixel',
				pixel_subheader: 'ชุดสคริปต์ที่เก็บข้อมูลกิจกรรมบนเว็บไซต์ เป็น Analytics Tools ที่ช่วยให้ Facebook บันทึกข้อมูลที่เกิดขึ้นบนเว็บไซต์ได้',
				og_title: 'Title',
				og_title_hint: 'จำกัดตัวอักษร %{count}/95',
				og_desc: 'Description',
				og_desc_hint: 'จำกัดตัวอักษร %{count}/155',
				og_image: 'ภาพประกอบ',
				og_image_info: 'ขนาดภาพแนะนำ 1200x630',
				validator: {
					meta_title: '^กรุณากรอก Title',
					meta_desc: '^กรุณากรอก Description',
					og_title: '^กรุณากรอก Title',
					og_desc: '^กรุณากรอก Description',
				},
				save_success: 'บันทึกข้อมูล Analytics เรียบร้อยแล้ว',
			},
			tab3: {
				title: 'บทความ',
				header: 'เลือกบทความประกอบการใช้งานของผู้ใช้บนเว็บไซต์สินค้า',
				howto_article_id: 'วิธีการใช้งาน',
				howto_article_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				payment_article_id: 'การจ่ายเงิน',
				payment_article_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				aboutus_article_id: 'เกี่ยวกับเว็บไซต์',
				aboutus_article_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				faq_article_id: 'คำถามที่พบบ่อย',
				faq_article_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				save_success: 'บันทึกรายละเอียดบทความเรียบร้อยแล้ว',
			}
		},
		banners: {
			title: 'รายการแบนเนอร์',
			header: 'รายการแบนเนอร์',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อแบนเนอร์',
			result_title: 'รายการแบนเนอร์ทั้งหมด',
			no_data: 'ไม่พบรายการแบนเนอร์',
			item_col: 'ชื่อแบนเนอร์',
			item_col2: 'ประเภท',
			item_col3: 'สถานะ',
			item_col4: 'Actions',
			delete_success: 'ลบข้อมูลแบนเนอร์เรียบร้อยแล้ว',
			type: {
				product: 'สินค้า',
				external: 'ลิงค์เว็บไซต์'
			},
			create: {
				header: 'สร้างข้อมูลแบนเนอร์',
				save_fail: 'ไม่สามารถสร้างข้อมูลแบนเนอร์ได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการแบนเนอร์',
				header: 'จัดการข้อมูลแบนเนอร์',
				form_header: 'แก้ไขแบนเนอร์',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับเว็บไซต์ซื้อขายสินค้า ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
				type: 'ประเภท',
				type_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				title_: 'ชื่อแบนเนอร์',
				title_hint: 'จำกัดตัวอักษร %{count}/100',
				ref_id: 'เลือกรายการสินค้า',
				ref_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				web_url: 'ลิงค์เว็บไซต์',
				web_url_placeholder: 'เช่น https://google.co.th',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงแบนเนอร์ต่อผู้ใช้เว็บไซต์ซื้อขายสินค้า',
				save_success: 'บันทึกการข้อมูลแบนเนอร์ใหม่เรียบร้อยแล้ว',
				validator: {
					title: '^กรุณากรอกชื่อแบนเนอร์'
				},
				image: {
					header: 'อัพโหลดภาพแบนเนอร์',
					hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					info: 'ขนาดภาพแนะนำ 1920x560'
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลแบนเนอร์'
				}
			}
		},
		articles: {
			title: 'รายการบทความ',
			header: 'รายการบทความ',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อบทความ',
			result_title: 'รายการบทความทั้งหมด',
			no_data: 'ไม่พบข้อมูลรายการบทความ',
			item_col: 'ชื่อบทความ',
			item_col2: 'สถานะ',
			item_col3: 'Actions',
			delete_success: 'ลบข้อมูลบทความเรียบร้อยแล้ว',
			create: {
				header: 'สร้างข้อมูลบทความ',
				save_fail: 'ไม่สามารถสร้างข้อมูลบทความได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการบทความ',
				header: 'จัดการข้อมูลบทความ',
				form_header: 'แก้ไขบทความ',
				form_sub_header: 'รายละเอียดบทความสำหรับผู้ใช้เว็บไซต์ซื้อขายสินค้า',
				title_: 'ชื่อบทความ',
				title_hint: 'จำกัดตัวอักษร %{count}/250',
				description: 'รายละเอียดแบบย่อ',
				description_hint: 'จำกัดตัวอักษร %{count}/250',
				tags: 'ติดแท็ก',
				tags_hint: 'จำกัดตัวอักษร %{count}/255',
				tags_placeholder: 'กรอกแท็กและกดปุ่ม "Enter"',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงบทความต่อผู้ใช้เว็บไซต์ซื้อขายสินค้า',
				content: 'เนื้อหาบทความ',
				content_hint: 'กรอกรายละเอียดที่เกี่ยวข้องกับเนื้อหา...',
				save_success: 'บันทึกข้อมูลบทความใหม่เรียบร้อยแล้ว',
				image: {
					header: 'อัพโหลดภาพปก',
					hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					info: 'ขนาดภาพแนะนำ 480x480'
				},
				validator: {
					title: '^กรุณากรอกชื่อบทความ',
					description: '^กรุณากรอกรายละเอียดแบบย่อ',
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลบทความ'
				}
			}
		},
		customer_orders: {
			title: 'รายการคำสั่งซื้อจากลูกค้า',
			header: 'รายการคำสั่งซื้อจากลูกค้า',
			search: {
				search_placeholder: 'หมายเลขคำสั่งซื้อ, ชื่อลูกค้า',
				status: 'สถานะ',
				all_status: 'ทั้งหมด',
				start_date: 'วันเริ่มต้น',
				end_date: 'ถึง'
			},
			result_title: 'รายการคำสั่งซื้อทั้งหมด',
			item_col: 'หมายเลขคำสั่งซื้อ',
			item_col_cod: 'COD',
			item_col2: 'ยอดรวมทั้งหมด',
			item_col3: 'สถานะ',
			item_col4: 'วันที่สั่งซื้อ',
			item_col5: 'Action',
			no_data: 'ไม่พบข้อมูลรายการคำสั่งซื้อจากลูกค้า',
			save_status_success: 'บันทึกสถานะสั่งซื้อเรียบร้อยแล้ว',
			status: {
				cancelled: 'ยกเลิกคำสั่งซื้อ',
				new: 'ได้รับคำสั่งซื้อ',
				paid: 'จ่ายเงินแล้ว',
				confirm_paid: 'ยืนยันการจ่ายแล้ว',
				ready_to_ship: 'พร้อมจัดส่ง',
				shipped: 'จัดส่งแล้ว',
				delivered: 'ส่งถึงปลายทางแล้ว',
				cancel: 'ยกเลิก',
				return: 'ส่งสินค้าคืน',
			},
			detail: {
				title: 'คำสั่งซื้อจากลูกค้า',
				header: 'จัดการข้อมูลคำสั่งซื้อจากลูกค้า',
				status_action: 'บันทึกสถานะ',
				shipping_action: 'เลือกขนส่งที่จะใช้บริการ',
				form_header: 'หมายเลขคำสั่งซื้อ',
				form_sub_header: 'รายการสินค้าและรายละเอียดที่อยู่จัดส่ง',
				created_date: 'จัดทำวันที่',
				sponsor: 'ผู้แนะนำ',
				full_name: 'ชื่อผู้สมัคร',
				external_remark: 'สิทธิประโยชน์',
				shipping_header: 'รายละเอียดจัดส่ง',
				billing_header: 'ใบกำกับภาษี',
				results: {
					item_col0: 'ลำดับ',
					item_col: 'ชื่อสินค้า',
					item_col2: 'ราคาต่อชิ้น',
					item_col3: 'จำนวน',
					item_col4: 'ยอดรวม',
					unit_price: 'ราคา',
					qty: 'จำนวน',
					total: 'ยอดรวม',
					shipping_price: 'ค่าจัดส่ง',
					discount_price: 'ส่วนลด',
					total_price: 'ราคาสินค้า',
					net_price: 'ยอดที่ได้รับชำระ',
				},
				payment: {
					header: 'รายละเอียดการชำระเงิน',
					cod: 'ลูกค้าเลือกชำระปลายทาง (COD)',
					no_data: 'ยังไม่ได้รับแจ้งการจ่ายเงิน',
					action: 'ยืนยันรับชำระเงิน',
					confirm_message: 'ยืนยันการชำระเงินถูกต้องตามคำสั่งซื้อ?',
					save_success: 'ยืนยันการชำระเงินเรียบร้อยแล้ว'
				},
				parcels: {
					header: 'รายการจัดเตรียมพัสดุ',
					sub_header: 'หากมีการปรับเปลี่ยนข้อมูลพัสดุ กรุณาบันทึกข้อมูล เพื่อแสดงรายการขนส่งตามจำนวนพัสดุ',
					cod_hint: '***ค่าเก็บเงินปลายทางอยู่ในช่วง 100 - 50,000 บาท',
					is_parcel_ready: 'เตรียมพัสดุพร้อมแล้วสำหรับการจัดส่ง',
					confirm_ready: 'กรุณายืนยันการเตรียมพัสดุก่อนการส่ง ซึ่งจะทำให้คุณจะไม่สามารถแก้ไขรายการพัสดุและตรวจสอบขนส่งได้',
					no_data: 'กรุณาเพิ่มรายการพัสดุที่บรรจุสินค้า',
					box: 'ขนาดกล่อง / ซอง',
					parcel_weight: 'น้ำหนัก',
					parcel_width: 'กว้าง',
					parcel_length: 'ยาว',
					parcel_height: 'สูง',
					g: 'กรัม',
					cm: 'ซม',
					cod_amount: 'เก็บเงินปลายทาง',
				},
				shipping: {
					header: 'ตรวจสอบขนส่ง',
					courier_id: 'เลือกขนส่ง',
					courier_parcel: 'ชิ้นที่ %{index} - %{remark}',
					courier_parcel_optional: 'สามารถส่งได้',
					courier_parcel_hint: '***ตัวอักษรสีแดงแทนพัสดุที่ขนส่งไม่สามารถส่งได้ กรุณาตรวจสอบพัสดุและเลือกขนส่งใหม่อีกครั้ง',
				},
				booking: {
					header: 'รายการจัดส่งพัสดุ',
					courier: 'ดำเนินการขนส่งโดย : %{courier}',
					date: 'อัพเดตสถานะเมื่อวันที่ : %{time}',
					parcel: 'พัสดุชิ้นที่ #%{index}',
					description: '%{title}, น้ำหนัก %{weight} กรัม',
					cod: 'เก็บเงินปลายทาง',
				},
				save_success: 'บันทึกข้อมูลเรียบร้อยแล้ว',
				remark: {
					header: 'ข้อมูลเข้าระบบ AAP',
					external_member_no: 'รหัสสมาชิก',
					remark: 'ลิงค์ติดตามสถานะสินค้า'
				},
				other_actions: {
					header: 'Convert',
					delete_hint: 'สร้างมูลสมาชิกระบบ AAP ให้ลูกค้านี้ อัตโนมัติ หากมีข้อมูลสมาชิกนี้อยู่ภายในระบบแล้ว จะไม่สามารถสร้างขึ้นใหม่ได้',
					btn_convert: 'เริ่มสร้างข้อมูลลูกค้า',
					btn_converted: 'สร้างข้อมูลลูกค้าแล้ว'
				}
			},
			waiting: {
				title: 'รายการขนส่งที่จัดเตรียมพัสดุ',
				header: 'รายการขนส่งที่จัดเตรียมพัสดุ',
				result_title: 'รายการขนส่งทั้งหมด',
				no_shipping_enabled: 'เปิดการใช้งานเชื่อมต่อบริการระบบขนส่ง เพื่อเข้าถึงการใช้งานที่สะดวกยิ่งขึ้น',
				item_col: 'ชื่อขนส่ง',
				item_col2: 'ช่วงเวลาตัดยอด',
				item_col3: 'การเข้ารับ',
				item_col4: 'จำนวนขั้นต่ำ',
				item_col5: 'จำนวนที่ต้องส่ง',
				item_col6: 'Action',
				action_booking: 'พร้อมส่งพัสดุ',
				status_booked: 'จัดส่งเรียบร้อยแล้ว',
				confirm_message: 'กรุณายืนยันรายละเอียดด้านล่าง ก่อนเริ่มการจัดส่งพัสดุทั้งหมด',
				no_data: 'ไม่มีพัสดุสำหรับการจัดส่งนี้',
			},
			tracking: {
				title: 'รายการจัดส่งสินค้า',
				header: 'รายการจัดส่งสินค้า',
				action: 'เพิ่มรายการ',
				search: {
					search_placeholder: 'ค้นหาจากหมายเลขจัดส่ง',
					status: 'สถานะ',
					all_status: 'ทั้งหมด',
					start_date: 'วันเริ่มต้น',
					end_date: 'ถึง'
				},
				no_shipping_enabled: 'เปิดการใช้งานเชื่อมต่อบริการระบบขนส่ง เพื่อเข้าถึงการใช้งานที่สะดวกยิ่งขึ้น',
				result_title: 'รายการจัดส่งสินค้าทั้งหมด',
				item_col: 'หมายเลขจัดส่ง (จำนวนพัสดุ)',
				item_col2: 'ชื่อขนส่ง',
				item_col3: 'วันที่จัดส่ง',
				item_col4: ' ',
				no_data: 'ไม่พบข้อมูลรายการจัดส่งสินค้า',
				modal: {
					title: 'หมายเลขจัดส่ง #%{no}',
					subheader: 'หมายเลขคำสั่งซื้อและรายการพัสดุตามรายละเอียดด้านล่างนี้',
					item_no: 'พัสดุ #%{index} - %{no}',
					item_action: 'ดูรายละเอียด',
					printing_action: 'สั่งพิมพ์ใบปะหน้า',
				},
				printing: {
					title: 'รายละเอียดใบปะหน้า',
					header: 'รายละเอียดใบปะหน้า #%{no}',
					action: 'สั่งพิมพ์ใบปะหน้า',
					search: {
						search_placeholder: 'ค้นหาจากหมายเลขจัดส่ง',
						size: {
							default: 'ขนาดใบปะหน้า',
							a4: 'กระดาษ A4',
							a5: 'กระดาษ A5',
							a6: 'กระดาษ A6',
							receipt: 'ใบเสร็จ',
							letter: 'ซองจดหมาย',
							letter4x6: 'ซองจดหมาย 4x6',
							sticker: 'สติกเกอร์ 8x8 เซนติเมตร',
							sticker4x6: 'สติกเกอร์ 4x6 นิ้ว',
							paperang: 'เครื่องปริ๊น Paperang',
						},
						tracking_code: 'หมายเลข Tracking Code',
						all_tracking_code: 'ทั้งหมด'
					},
					result_title: 'รายการจัดส่งสินค้าทั้งหมด',
					item_col: 'หมายเลขจัดส่ง (จำนวนพัสดุ)',
					item_col2: 'ชื่อขนส่ง',
					item_col3: 'วันที่จัดส่ง',
					item_col4: ' ',
					no_data: 'ไม่พบข้อมูลรายการจัดส่งสินค้า',
					modal: {
						title: 'หมายเลขจัดส่ง #%{no}',
						subheader: 'หมายเลขคำสั่งซื้อและรายการพัสดุตามรายละเอียดด้านล่างนี้',
						item_no: 'พัสดุ #%{index} - %{no}',
						item_action: 'ดูรายละเอียด',
						printing_action: 'สั่งพิมพ์ใบปะหน้า',
					}
				},
			},
		}
	},
	redeem: {
		gifts: {
			title: 'รายการของรางวัล',
			header: 'รายการของรางวัล',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อรางวัล',
			result_title: 'รายการรางวัลทั้งหมด',
			item_col: 'ชื่อรางวัล',
			item_col2: 'คะแนน',
			item_col3: 'ช่วงเวลาแลก',
			item_col4: 'สถานะ',
			item_col5: 'Actions',
			no_data: 'ไม่พบข้อมูลรายการของรางวัล',
			delete_success: 'ลบข้อมูลรางวัลเรียบร้อยแล้ว',
			create: {
				header: 'สร้างข้อมูลรางวัล',
				save_fail: 'ไม่สามารถสร้างข้อมูลรางวัลได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการของรางวัล',
				header: 'จัดการข้อมูลรางวัล',
				status_active: 'เปิดสาธารณะ',
				status_inactive: 'ซ่อนการมองเห็น',
				form_header: 'แก้ไขรางวัล',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับการแลกรางวัลของสมาชิก ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
				title_: 'ชื่อรางวัล',
				title_hint: 'จำกัดตัวอักษร %{count}/250',
				point: 'จำนวนคะแนนที่ใช้แลก',
				start_date: 'วันที่เริ่มต้น',
				end_date: 'สิ้นสุด',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงรางวัลต่อสมาชิกที่ใช้ระบบ',
				save_success: 'บันทึกการข้อมูลรางวัลใหม่เรียบร้อยแล้ว',
				image: {
					header: 'อัพโหลดภาพปก',
					hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					info: 'ขนาดภาพแนะนำ 480x480'
				},
				validator: {
					name: '^กรุณากรอกชื่อรางวัล',
					point: '^กรุณากรอกคะแนน',
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลรางวัลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลรางวัล'
				}
			}
		},
		histories: {
			title: 'ประวัติแลกรางวัล',
			header: 'ประวัติแลกรางวัล',
			search_placeholder: 'ชื่อรางวัล, ชื่อผู้ใช้',
			result_title: 'รายการประวัติแลกรางวัลทั้งหมด',
			item_col: 'ชื่อรางวัล/คะแนน',
			item_col2: 'ผู้แลกรางวัล',
			item_col3: 'วันที่แลก',
			item_col4: 'Actions',
			item_col4_approve: 'อนุมัติ',
			item_col4_reject: 'ไม่อนุมัติ',
			status: {
				approved: 'อนุมัติแล้ว',
				rejected: 'ไม่ผ่านการอนุมัติ'
			}
		},
		settings: {
			title: 'ตั้งค่าแลกรางวัล',
			header: 'ตั้งค่าแลกรางวัล',
			tab: {
				default: 'ตั้งค่าทั่วไป',
				header: 'ข้อมูลทั่วไป',
				description: 'สิทธิในการล้างข้อมูลคะแนนของสมาชิกทุกคนเป็นของผู้ดูแลระบบผู้เดียวเท่านั้น การเก็บ-ลบคะแนนอยู่ในส่วนของโปรโมชันที่เจ้าของแบรนด์เป็นผู้กำหนด',
				action: 'ล้างคะแนนทั้งหมด',
				confirm_clear_point: 'ต้องการล้างข้อมูลคะแนนของสมาชิกทุกคนใช่หรือไม่ ?',
				save_success: 'คะแนนของสมาชิกทุกคนถูกล้างเรียบร้อยแล้ว',
			},
		}
	},
	guest: {
		guests: {
			title: 'รายการผู้มุ่งหวังของระบบ',
			header: 'รายการผู้มุ่งหวังของระบบ',
			search_placeholder: 'ชื่อผู้มุ่งหวัง',
			result_title: 'รายการผู้มุ่งหวังทั้งหมด',
			item_col: 'ชื่อ-อีเมล',
			item_col_waiting: 'รออนุมัติอัพเกรด',
			item_col_approved: 'อนุมัติอัพเกรดแล้ว',
			item_col2: 'วันที่สมัคร',
			item_col3: 'อนุมัติคูปอง',
			item_col3_idle: 'ยังไม่ส่งอนุมัติ',
			item_col3_pending: 'รอการตรวจสอบ',
			item_col3_approved: 'อนุมัติแล้ว',
			item_col4: 'Convert',
			item_col4_btn_waiting: 'Waiting',
			item_col4_btn_convert: 'Convert',
			item_col4_btn_converted: 'Converted',
			item_col5: 'Actions',
			no_data: 'ไม่พบรายการข้อมูลผู้มุ่งหวังของระบบ',
		},
		orders: {
			title: 'รายการคำสั่งซื้อของระบบ',
			header: 'รายการคำสั่งซื้อของระบบ',
			search_placeholder: 'หมายเลขคำสั่งซ์้อ',
			result_title: 'รายการคำสั่งซื้อทั้งหมด',
			item_col: 'หมายเลขคำสั่งซื้อ',
			item_col_cod: 'COD',
			item_col2: 'ยอมรวมทั้งหมด',
			item_col3: 'สถานะ',
			item_col4: 'วันที่สั่งซื้อ',
			item_col5: 'Actions',
			no_data: 'ไม่พบรายการข้อมูลผู้มุ่งหวังของระบบ',
		},
		profile: {
			title: 'ตั้งค่า',
			tab2: {
				default: 'Upgrade',
				header: 'หลักฐานการอัพเกรดผู้มุ่งหวัง',
				sub_header: 'เลือกคอนเทนท์ที่ต้องการให้สมาชิกศึกษาเพิ่มเติมทั้งบทความและวิดีโอ',
				group_name: 'อัพเกรดผู้มุ่งหวังเป็น',
				account_name: 'ชื่อบัญชี',
				bank_name: 'ธนาคาร',
				transaction_date: 'วันที่โอนเงิน',
				transaction_time: 'เวลาที่โอนงิน',
				btn_approve: 'อนุมัติการอัพเกรด',
				btn_approved: 'อนุมัติแล้ว',
				save_success: 'อนุมัติการอัพเกรดเรียบร้อยแล้ว',
			},
		},
		settings: {
			title: 'ตั้งค่าเว็บไซต์และผู้มุ่งหวัง',
			header: 'ตั้งค่าเว็บไซต์และผู้มุ่งหวัง',
			tab: {
				default: 'ตั้งค่าทั่วไป',
				image: {
					header: 'โลโก้และไอคอน',
					logo: 'โลโก้บนหน้าเว็บไซต์',
					logo_hint: 'คลิกหรือลากไฟล์ตรงนี้เพื่ออัพโหลดโลโก้',
					logo_info: 'ขนาดความสูงภาพแนะนำ 64px (ความกว้างขึ้นอยู่กับสัดส่วนภาพ)',
					favicon: 'Favicon',
					favicon_desc: 'ไอคอนสำหรับแสดงผลบน Web browser และ Bookmark, ขนาดภาพแนะนำ 64x64',
					favicon_hint: 'คลิกหรือลากไฟล์ตรงนี้เพื่ออัพโหลด Favicon',
					favicon_info: 'ขนาดภาพแนะนำ 64x64',
				},
				header: 'ข้อมูลทั่วไป',
				site_title: 'Site title',
				site_title_hint: 'จำกัดตัวอักษร %{count}/50',
				tagline: 'Tagline',
				tagline_hint: 'คำอธิบายสั้นๆ สำหรับเว็บไซต์, จำกัดตัวอักษร %{count}/100',
				editor: 'ข้อความต้อนรับ',
				editor_placeholder: 'กรอกข้อความต้อนรับ',
				is_welcome_enabled: 'เปิดเผยต่อสาธารณะ',
				is_welcome_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงผลข้อความต้อนรับบนเว็บไซต์ผู้มุ่งหวัง',
				save_success: 'บันทึกการตั้งค่าทั่วไปเรียบร้อยแล้ว',
				validator: {
					site_title: '^กรุณากรอก Site title',
					tagline: '^กรุณากรอก Tag line',
				},
			},
			tab2: {
				default: 'กำหนดสิทธิ',
				header: 'กำหนดสิทธิ์การใช้งานเมนู',
				sub_header: 'กำหนดการเข้าถึงเมนูต่างๆ ภายในเว็บไซต์ สำหรับผู้มุ่งหวัง',
				save_success: 'บันทึกสิทธิ์การใช้งานเมนูเรียบร้อยแล้ว',
			},
			tab3: {
				default: 'ข้อความการอัพเกรด',
				editor_placeholder: 'กรอกข้อความอัพเกรด',
				save_success: 'บันทึกข้อความอัพเกรดเรียบร้อยแล้ว',
			},
		},
	},
	member: {
		groups: {
			title: 'รายการระดับ',
			header: 'รายการระดับ',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อระดับ',
			result_title: 'รายการระดับทั้งหมด',
			no_data: 'ไม่พบข้อมูลรายการระดับ',
			item_col: 'ชื่อระดับ',
			item_col2: 'สถานะ',
			item_col3: 'Actions',
			delete_success: 'ลบข้อมูลระดับเรียบร้อยแล้ว',
			create: {
				header: 'สร้างข้อมูลระดับ',
				save_fail: 'ไม่สามารถสร้างข้อมูลระดับได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการระดับ',
				header: 'จัดการข้อมูลระดับ',
				status_active: 'เปิดสาธารณะ',
				status_inactive: 'ซ่อนการมองเห็น',
				form_header: 'แก้ไขระดับ',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับสมาชิก ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
				code: 'รหัส',
				code_hint: 'จำกัดตัวอักษร %{count}/10',
				name: 'ชื่อระดับ',
				name_hint: 'จำกัดตัวอักษร %{count}/100',
				description: 'รายละเอียดแบบย่อ',
				description_hint: 'จำกัดตัวอักษร %{count}/250',
				price: 'ราคาเต็ม',
				sale_price: 'ราคาขาย',
				min_promote_order_price: 'ยอดสั่งซื้อสะสมตั้งแต่',
				max_promote_order_price: 'ถึง',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงระดับต่อสมาชิกที่ใช้ระบบ',
				save_success: 'บันทึกการข้อมูลระดับใหม่เรียบร้อยแล้ว',
				validator: {
					code: '^กรุณากรอกรหัสระดับ',
					name: '^กรุณากรอกชื่อระดับ',
					description: '^กรุณากรอกรายละเอียดแบบย่อ',
					price: '^กรุณากรอกราคาเต็ม',
					sale_price: '^กรุณากรอกราคาขาย'
				},
				nav_config: {
					header: 'กำหนดสิทธิ์การใช้งานเมนู',
					sub_header: 'กำหนดการเข้าถึงเมนูต่างๆ ภายในเว็บไซต์ สำหรับสมาชิกที่อยู่ในกลุ่มนี้',
				},
				image: {
					header: 'อัพโหลดภาพบัตรตัวแทน',
					hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					info: 'ขนาดภาพแนะนำ 600x600'
				},
				features: {
					header: 'ตั้งค่าการใช้งานเซลเพจ',
					page_id: 'เซลเพจที่ใช้ได้',
					custom_page: 'อนุญาติให้สร้างเซลเพจได้เอง',
					custom_page_limit: 'จำนวนที่สร้างได้',
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสมาชิกที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลระดับ'
				}
			}
		},
		members: {
			title: 'รายการสมาชิกของระบบ',
			header: 'รายการสมาชิกของระบบ',
			action: 'เพิ่มรายการ',
			change_view_network: 'ดูมุมมองเครือข่าย',
			change_view_table: 'ดูมุมมองตาราง',
			import_csv: 'อัพโหลด CSV',
			search_placeholder: 'ชื่อหรือรหัสของสมาชิก',
			result_title: 'รายการสมาชิกทั้งหมด',
			item_col: 'ชื่อ-อีเมล',
			item_col2: 'ระดับ',
			item_col3: 'Social Network',
			item_col4: 'เข้าใช้งานล่าสุด',
			item_col5: 'วันที่สมัคร',
			item_col6: 'สถานะ',
			item_col7: 'Actions',
			delete_success: 'ลบข้อมูลสมาชิกเรียบร้อยแล้ว',
			network_title: 'แสดงสมาชิกตามลำดับขั้น',
			no_data: 'ไม่พบรายการข้อมูลสมาชิกของระบบ',
			filter: {
				section: 'Info',
				group_id: 'ระดับ',
			},
			create: {
				header: 'สร้างข้อมูลสมาชิก',
				save_fail: 'ไม่สามารถสร้างข้อมูลสมาชิกได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			upload: {
				header: 'อัพโหลดข้อมูลผ่านไฟล์ CSV',
				caption: 'คลิกเลือกไฟล์ข้อมูลของคุณด้านล่างนี้',
				result: '***ข้อมูลภายในไฟล์มีทั้งหมด %{count} แถว',
				save_fail: 'ไม่สามารถสร้างข้อมูลได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				group_id: 'ระดับสมาชิก',
				group_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				member_id: 'รายชื่อผู้แนะนำ',
				member_id_placeholder: 'ค้นหาชื่อ',
				email: 'อีเมล',
				password: 'รหัสผ่าน',
				no: 'รหัสสมาชิก',
				sub_domain: 'Sub-domain',
				firstname: 'ชื่อ',
				lastname: 'นามสกุล',
				phone: 'เบอร์โทรศัพท์',
				phone_placeholder: '081 234 5678',
				validator: {
					email: '^กรุณากรอกอีเมล',
					email_invalid: '^อีเมล์ไม่ถูกต้อง',
					password: '^กรุณากรอกรหัสผ่าน',
					firstname: '^กรุณากรอกชื่อ',
					no: '^กรุณากรอกรหัสสมาชิก',
					sub_domain: '^กรุณากรอก Sub-domain',
					lastname: '^กรุณากรอกนามสกุล',
					phone: '^กรุณากรอกเบอร์โทรศัพท์',
				},
				save_success: 'สร้างข้อมูลผู้ใช้เรียบร้อย',
			}
		},
		profile: {
			title: 'ตั้งค่า',
			tab: {
				default: 'ข้อมูลทั่วไป',
				profile: 'ข้อมูลส่วนตัว',
				group_id: 'Member group',
				group_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				member_id: 'รายชื่อสมาชิก Sponsor',
				member_id_placeholder: 'ค้นหาชื่อ',
				sub_domain: 'ชื่อเว็บไซต์ส่วนตัว',
				email: 'อีเมล',
				username: 'ชื่อล็อกอิน',
				firstname: 'ชื่อจริง',
				lastname: 'นามสกุล',
				nick_name: 'ชื่อเล่น',
				birthdate: 'วันเกิด',
				card_id: 'หมายเลขบัตรประชาชน',
				phone: 'เบอร์โทรศัพท์',
				phone_placeholder: '081 234 5678',
				address: 'ที่อยู่',
				postal_code: 'รหัสไปรษณีย์',
				province: 'จังหวัด',
				city: 'อำเภอ',
				district: 'ตำบล',
				social: 'Social Network',
				line: 'Line Id',
				line_placeholder: '@line',
				facebook: 'Facebook',
				facebook_placeholder: 'www.facebook.com',
				instagram: 'Instagram',
				instagram_placeholder: 'www.instagram.com',
				save_success: 'บันทึกข้อมูลส่วนตัวเรียบร้อยแล้ว',
			},
			tab2: {
				default: 'Contents',
				header: 'คำอธิบายเพิ่มเติม',
				sub_header: 'เลือกคอนเทนท์ที่ต้องการให้สมาชิกศึกษาเพิ่มเติมทั้งบทความและวิดีโอ',
				action: 'เพิ่มรายการ',
				create: {
					header: 'เลือก Content สำหรับสมาชิก',
					category_id: 'หมวดหมู่',
					category_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					article: 'บทความ',
					video: 'วิดีโอ',
					content_ids: 'ค้นหาคอนเทนต์',
					content_ids_placeholder: 'ค้นหาชื่อ'
				},
				delete_success: 'ลบรายการเรียบร้อยแล้ว',
				save_success: 'บันทึกรายการคอนเทนต์เรียบร้อยแล้ว',
			},
			tab3: {
				default: 'Security',
				change_password: 'เปลี่ยนรหัสผ่าน',
				password: 'รหัสผ่าน',
				confirm_password: 'ยืนยันรหัสผ่าน',
				save_success: 'บันทึกรหัสผ่านใหม่เรียบร้อยแล้ว',
				validator: {
					password: '^กรุณากรอกรหัสผ่าน',
					confirm_password: '^กรุณากรอกยืนยันรหัสผ่าน',
					password_not_match: 'รหัสผ่านไม่ตรงกัน'
				},
			},
			tab4: {
				default: 'เอกสาร',
				header: 'รายละเอียดบัญชีธนาคาร',
				account_name: 'ชื่อบัญชี',
				bank_name: 'ธนาคาร',
			},
		},
		qualified: {
			title: 'รายการผู้ประสบความสำเร็จ',
			header: 'รายการผู้ประสบความสำเร็จ',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อสมาชิก',
			result_title: 'รายการสมาชิกทั้งหมด',
			item_col: 'ชื่อ-อีเมล',
			item_col2: 'ระดับ',
			item_col3: 'วันที่สมัคร',
			item_col4: 'Actions',
			delete_success: 'ลบข้อมูลการผู้ประสบความสำเร็จประจำเดือนเรียบร้อยแล้ว',
			no_data: 'ไม่พบรายการข้อมูลผู้ประสบความสำเร็จ',
			filter: {
				section: 'Info',
				month: 'เดือนที่',
				month_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				year: 'ปีที่',
				year_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
			},
			create: {
				header: 'สร้างข้อมูลผู้ประสบความสำเร็จประจำเดือน',
				save_fail: 'ไม่สามารถสร้างข้อมูลได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการผู้ประสบความสำเร็จ',
				header: 'จัดการข้อมูลผู้ประสบความสำเร็จ',
				form_header: 'แก้ไขข้อมูลรายละเอียด',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับข้อมูลสมาชิก ซึ่งจะแสดงผลต่อสมาชิกอื่นๆ ในข้อมูลผู้ประสบความสำเร็จ',
				month: 'เดือนที่',
				month_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				year: 'ปีที่',
				year_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				member_id: 'รายชื่อสมาชิก',
				member_id_placeholder: 'ค้นหาชื่อ',
				text: 'ข้อความ',
				save_success: 'บันทึกการข้อมูลผู้ประสบความสำเร็จใหม่เรียบร้อยแล้ว',
				validator: {
					text: '^กรุณากรอกข้อความ',
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะไม่มีผลกระทบกับข้อมูลสมาชิกที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลผู้ประสบความสำเร็จ'
				}
			}
		},
		quota: {
			title: 'รายการสุ่มรายชื่อ',
			header: 'รายการสุ่มรายชื่อ',
			option_not_match: 'ตัวเลือกการสุ่มรายชื่อไม่ได้เลือก "กำหนดรายชื่อสมาชิก" รายการด้านล่างจึงไม่มีผลกับการสุ่ม',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อสมาชิก',
			result_title: 'รายการสมาชิกทั้งหมด',
			item_col: 'ชื่อ-อีเมล',
			item_col2: 'โควต้า (รับจริง/เป้าหมาย)',
			item_col3: 'สถานะ',
			item_col4: 'Actions',
			delete_success: 'ลบข้อมูลรายการโควต้าเรียบร้อยแล้ว',
			no_data: 'ไม่พบรายการข้อมูลโควต้าสุ่มรายชื่อ',
			filter: {
				section: 'Info',
				month: 'เดือนที่',
				month_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				year: 'ปีที่',
				year_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
			},
			create: {
				header: 'สร้างข้อมูลโควต้าสุ่มรายชื่อ',
				save_fail: 'ไม่สามารถสร้างข้อมูลได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการโควต้าสุ่มรายชื่อ',
				header: 'จัดการข้อมูลโควต้าสุ่มรายชื่อ',
				form_header: 'แก้ไขข้อมูลรายละเอียด',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับข้อมูลสมาชิก ซึ่งจะแสดงผลต่อสมาชิกอื่นๆ ในข้อมูลโควต้าสุ่มรายชื่อ',
				month: 'เดือนที่',
				month_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				year: 'ปีที่',
				year_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				member_id: 'รายชื่อสมาชิก',
				member_id_placeholder: 'ค้นหาชื่อ',
				actual_qty: 'จำนวนรับจริง',
				target_qty: 'จำนวนเป้าหมาย',
				save_success: 'บันทึกการข้อมูลโควต้าใหม่เรียบร้อยแล้ว',
				validator: {
					text: '^กรุณากรอกข้อความ',
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะไม่มีผลกระทบกับข้อมูลสมาชิกที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลโควต้าสุ่มรายชื่อ'
				}
			}
		},
		settings: {
			title: 'ตั้งค่าเว็บไซต์และสมาชิก',
			header: 'ตั้งค่าเว็บไซต์และสมาชิก',
			tab: {
				default: 'ตั้งค่าทั่วไป',
				image: {
					header: 'โลโก้และไอคอน',
					logo: 'โลโก้บนหน้าเว็บไซต์',
					logo_hint: 'คลิกหรือลากไฟล์ตรงนี้เพื่ออัพโหลดโลโก้',
					logo_info: 'ขนาดความสูงภาพแนะนำ 64px (ความกว้างขึ้นอยู่กับสัดส่วนภาพ)',
					favicon: 'Favicon',
					favicon_desc: 'ไอคอนสำหรับแสดงผลบน Web browser และ Bookmark, ขนาดภาพแนะนำ 64x64',
					favicon_hint: 'คลิกหรือลากไฟล์ตรงนี้เพื่ออัพโหลด Favicon',
					favicon_info: 'ขนาดภาพแนะนำ 64x64',
				},
				header: 'ข้อมูลทั่วไป',
				site_title: 'Site title',
				site_title_hint: 'จำกัดตัวอักษร %{count}/50',
				tagline: 'Tagline',
				tagline_hint: 'คำอธิบายสั้นๆ สำหรับเว็บไซต์, จำกัดตัวอักษร %{count}/100',
				authen_bg_url: 'ภาพประกอบหน้าเข้าสู่ระบบ',
				authen_bg_url_hint: 'คลิกหรือลากไฟล์ตรงนี้เพื่ออัพโหลด',
				authen_bg_url_info: 'ขนาดภาพแนะนำ 960x720',
				editor: 'ข้อความต้อนรับ',
				editor_placeholder: 'กรอกข้อความต้อนรับ',
				is_welcome_enabled: 'เปิดเผยต่อสาธารณะ',
				is_welcome_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงผลข้อความต้อนรับบนเว็บไซต์สมาชิก',
				save_success: 'บันทึกการตั้งค่าทั่วไปเรียบร้อยแล้ว',
				validator: {
					site_title: '^กรุณากรอก Site title',
					tagline: '^กรุณากรอก Tag line',
				},
			},
			tab2: {
				default: 'สุ่มรายชื่อ',
				header: 'ตัวเลือการสุ่มรายชื่อสำหรับสมาชิก',
				option: 'เฉพาะ Upline สูงสุด',
				option_hint: 'รายชื่อที่ลงทะเบียนเข้ามาจะถูกส่งต่อให้กับ Upline สูงสุดโดยอัตโนมัติ',
				option2: 'สมาชิกทุกคน',
				option2_hint: 'สมาชิกทุกคนในระบบจะได้รับรายชื่อลงทะเบียน โดยระบบจะสุ่มรายชื่อส่งให้สมาชิก',
				option3: 'กำหนดรายชื่อสมาชิก',
				option3_hint: 'ผู้ดูแลกำหนดสมาชิกที่จะได้รับรายชื่อตามโควต้าที่กำหนด โดยสามารถกำหนดได้จากเมนู "สุ่มรายชื่อ"',
				save_success: 'บันทึกตัวเลือกสุ่มเรียบร้อยแล้ว',
			},
			tab3: {
				default: 'เงื่อนไขของระบบ',
				header: 'รายละเอียดเงื่อนไขและข้อตกลง',
				image: {
					header: 'ภาพประกอบรายละเอียด',
					banner_hint: 'คลิกหรือลากไฟล์ตรงนี้เพื่ออัพโหลดโลโก้',
					banner_info: 'ขนาดภาพแนะนำ 640x640',
				},
				editor_placeholder: 'กรอกข้อความเงื่อนไขและข้อตกลง',
				save_success: 'บันทึกตัวเลือกสุ่มเรียบร้อยแล้ว',
			},
		},
	},
	kpi: {
		achievements: {
			title: 'รายการเป้าหมาย',
			header: 'รายการเป้าหมาย',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อเป้าหมาย',
			result_title: 'รายการเป้าหมายทั้งหมด',
			item_col: 'ชื่อเป้าหมาย',
			item_col2: 'ช่วงเวลา',
			item_col3: 'สถานะ',
			item_col4: 'Actions',
			no_data: 'ไม่พบข้อมูลรายการเป้าหมาย',
			delete_success: 'ลบข้อมูลเป้าหมายเรียบร้อยแล้ว',
			create: {
				header: 'สร้างข้อมูลเป้าหมาย',
				save_fail: 'ไม่สามารถสร้างข้อมูลเป้าหมายได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการเป้าหมาย',
				header: 'จัดการข้อมูลเป้าหมาย',
				status_active: 'เปิดสาธารณะ',
				status_inactive: 'ซ่อนการมองเห็น',
				form_header: 'แก้ไขเป้าหมาย',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับการวัดผล KPI ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
				type: 'ประเภท',
				type_simple: 'พัฒนาตัวเอง',
				type_member_order: 'เปิดออเดอร์',
				type_customer_order: 'ขายสินค้า',
				type_content: 'การเรียนรู้',
				type_lead: 'ค้นหาผู้มุ่งหวัง',
				type_line_share: 'แชร์ผ่าน Line',
				type_sale_page_share: 'แชร์ Sale Page',
				type_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				title_: 'ชื่อเป้าหมาย',
				title_hint: 'จำกัดตัวอักษร %{count}/100',
				ref_id_all: 'ทั้งหมด',
				ref_id_member_order: 'เลือกสินค้า',
				ref_id_customer_order: 'เลือกสินค้า',
				ref_id_content: 'เลือกบทความหรือวิดีโอ',
				ref_id_line_share: 'เลือกข้อความ Line',
				ref_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				target_unit_member_order: 'ยอดเปิดออเดอร์ของสินค้า (บาท)',
				target_unit_customer_order: 'ยอดขายสินค้า (บาท)',
				target_unit_content: 'ใช้เวลา (นาที)',
				target_unit_line_share: 'จำนวนการแชร์ (ครั้ง)',
				target_unit_sale_page_share: 'จำนวนการแชร์ (ครั้ง)',
				point: 'คะแนน',
				start_date: 'วันเริ่มต้น',
				end_date: 'สิ้นสุด',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงเป้าหมายต่อสมาชิกที่ใช้ระบบ',
				save_success: 'บันทึกการข้อมูลเป้าหมายใหม่เรียบร้อยแล้ว',
				validator: {
					title: '^กรุณากรอกชื่อเป้าหมาย',
					target_unit: '^กรุณากรอกข้อมูล',
					point: '^กรุณากรอกคะแนน',
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลเป้าหมาย'
				}
			}
		},
		leaders: {
			title: 'รายการผู้พิชิตเป้าหมาย',
			header: 'รายการผู้พิชิตเป้าหมาย',
			search_placeholder: 'ชื่อสมาชิก',
			result_title: 'รายการสมาชิกทั้งหมด',
			item_col: 'ชื่อ-อีเมล',
			item_col2: 'ระดับ',
			item_col3: 'คะแนน',
			no_data: 'ไม่พบข้อมูลรายการผู้พิชิตเป้าหมาย',
		},
	},
	tool: {
		liff: {
			categories: {
				title: 'รายการหมวดหมู่',
				header: 'รายการหมวดหมู่',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่อหมวดหมู่',
				result_title: 'รายการหมวดหมู่ทั้งหมด',
				no_data: 'ไม่พบข้อมูลรายการหมวดหมู่',
				item_col: 'ชื่อหมวดหมู่',
				item_col2: 'สถานะ',
				item_col3: 'Actions',
				delete_success: 'ลบข้อมูลหมวดหมู่เรียบร้อยแล้ว',
				create: {
					header: 'สร้างข้อมูลหมวดหมู่',
					save_fail: 'ไม่สามารถสร้างข้อมูลหมวดหมู่ได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการหมวดหมู่',
					header: 'จัดการข้อมูลหมวดหมู่',
					form_header: 'แก้ไขหมวดหมู่',
					form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับการแชร์ผ่านไลน์ ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
					name: 'ชื่อหมวดหมู่',
					name_hint: 'จำกัดตัวอักษร %{count}/100',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงหมวดหมู่ต่อสมาชิกที่ใช้ระบบ',
					save_success: 'บันทึกการข้อมูลหมวดหมู่ใหม่เรียบร้อยแล้ว',
					validator: {
						name: '^กรุณากรอกชื่อหมวดหมู่',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลหมวดหมู่'
					}
				}
			},
			contents: {
				title: 'รายการ Line Content',
				header: 'รายการ Line Content',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่อ Content',
				result_title: 'รายการ Content ทั้งหมด',
				no_data: 'ไม่พบรายการ Content',
				item_col: 'ชื่อ Content',
				item_col2: 'หมวดหมู่',
				item_col3: 'สถานะ',
				item_col4: 'Actions',
				delete_success: 'ลบข้อมูล Content เรียบร้อยแล้ว',
				create: {
					header: 'สร้างข้อมูล Content',
					save_fail: 'ไม่สามารถสร้างข้อมูล Content ได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการ Content',
					header: 'จัดการข้อมูล Content',
					form_header: 'แก้ไข Content',
					form_sub_header: 'รายละเอียด Content สำหรับส่งให้กลุ่มเป้าหมายผ่าน Line',
					category_id: 'หมวดหมู่',
					category_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					title_: 'ชื่อ Content',
					title_hint: 'จำกัดตัวอักษร %{count}/250',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการสถานะการใช้งาน Content',
					content: 'เนื้อหาเทมเพลต',
					content_hint: 'กรอกรายละเอียดที่เกี่ยวข้องกับเนื้อหา...',
					save_success: 'บันทึกข้อมูล Content ใหม่เรียบร้อยแล้ว',
					image: {
						header: 'อัพโหลดภาพปก',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ ความสูง 480px'
					},
					share: {
						header: 'รายการข้อความสำหรับแชร์',
						no_data: 'ไม่พบรายการข้อความ',
						type: {
							text: 'ข้อความ',
							image: 'รูปภาพ',
						},
						modal: {
							create_header: 'เพิ่มข้อความ',
							edit_header: 'แก้ไขข้อความ',
							type: 'ประเภทข้อความ',
							text: 'รายละเอียดข้อความ',
							image: {
								header: 'อัพโหลดภาพปก',
								hint: 'คลิกหรือลากไฟล์มาตรงนี้',
								info: 'ขนาดภาพแนะนำ ความสูง 800px และแนวตั้ง'
							},
						}
					},
					validator: {
						title: '^กรุณากรอกชื่อ Content',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูล Content'
					}
				}
			},
		},
		email: {
			categories: {
				title: 'รายการหมวดหมู่',
				header: 'รายการหมวดหมู่',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่อหมวดหมู่',
				result_title: 'รายการหมวดหมู่ทั้งหมด',
				item_col: 'ชื่อหมวดหมู่',
				item_col2: 'สถานะ',
				item_col3: 'Actions',
				delete_success: 'ลบข้อมูลหมวดหมู่เรียบร้อยแล้ว',
				create: {
					header: 'สร้างข้อมูลหมวดหมู่',
					save_fail: 'ไม่สามารถสร้างข้อมูลหมวดหมู่ได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการหมวดหมู่',
					header: 'จัดการข้อมูลหมวดหมู่',
					form_header: 'แก้ไขหมวดหมู่',
					form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับเทมเพลต ซึ่งจะแสดงผลต่อกลุ่มเป้าหมายเมื่อมีการเปิดเผยต่อสาธารณะ',
					name: 'ชื่อหมวดหมู่',
					name_hint: 'จำกัดตัวอักษร %{count}/100',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงหมวดหมู่ต่อการส่งเทพเพลต',
					save_success: 'บันทึกการข้อมูลหมวดหมู่ใหม่เรียบร้อยแล้ว',
					validator: {
						name: '^กรุณากรอกชื่อหมวดหมู่',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลหมวดหมู่'
					}
				}
			},
			templates: {
				title: 'รายการเทมเพลต',
				header: 'รายการเทมเพลต',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ชื่อเทมเพลต',
				result_title: 'รายการเทมเพลตทั้งหมด',
				no_data: 'ไม่พบรายการเทมเพลต',
				item_col: 'ชื่อเทมเพลต',
				item_col2: 'สถานะ',
				item_col3: 'Actions',
				delete_success: 'ลบข้อมูลเทมเพลตเรียบร้อยแล้ว',
				create: {
					header: 'สร้างข้อมูลเทมเพลต',
					save_fail: 'ไม่สามารถสร้างข้อมูลเทมเพลตได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					title: 'แก้ไขรายการเทมเพลต',
					header: 'จัดการข้อมูลเทมเพลต',
					form_header: 'แก้ไขเทมเพลต',
					form_sub_header: 'รายละเอียดเทมเพลตสำหรับส่งให้กลุ่มเป้าหมาย',
					category_id: 'หมวดหมู่อีเมล',
					category_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					title_: 'ชื่อเทมเพลต',
					title_hint: 'จำกัดตัวอักษร %{count}/250',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการสถานะการใช้งานเทมเพลต',
					content: 'เนื้อหาเทมเพลต',
					content_hint: 'กรอกรายละเอียดที่เกี่ยวข้องกับเนื้อหา...',
					save_success: 'บันทึกข้อมูลเทมเพลตใหม่เรียบร้อยแล้ว',
					validator: {
						title: '^กรุณากรอกชื่อเทมเพลต',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลเทมเพลต'
					}
				}
			},
			settings: {
				title: 'ตั้งค่าอีเมล',
				header: 'ตั้งค่าอีเมล',
				detail: {
					form_header: 'ส่งอีเมลจากระบบ',
					form_sub_header: 'รายละเอียดอีเมล์สำหรับการส่งอีเมลของระบบทั้งหมด',
					host: 'Host',
					port: 'Port',
					username: 'อีเมล',
					password: 'รหัสผ่าน',
					from_email: 'ชื่อผู้ส่งอีเมล',
					features: {
						header: 'ทดสอบการส่งอีเมล',
						sub_header: 'กรอกอีเมลปลายทางเพื่อทดสอบการส่งอีเมลจากรายละเอียดการส่ง',
						email: 'อีเมลปลายทาง',
						action: 'ส่งอีเมลทดสอบ',
						validator: {
							email: '^กรุณากรอกอีเมลปลายทาง',
							email_invalid: '^อีเมล์ไม่ถูกต้อง',
						},
					},
					save_success: 'บันทึกข้อมูลตั้งค่าอีเมลเรียบร้อยแล้ว',
					validator: {
						host: '^กรุณากรอก Host',
						port: '^กรุณากรอก Port',
						username: '^กรุณากรอกอีเมล',
						password: '^กรุณากรอกรหัสผ่าน',
						from_email: '^กรุณากรอกชื่อผู้ส่งอีเมล',
					},
				}
			},
		},
	},
};
