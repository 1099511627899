import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
	MenuItem,
	MenuList,
	Popover,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
	root: {
	},
}));

function AvatarMenuPopover({
	onItemClick,
	anchorEl,
	...rest
}) {
	const classes = useStyles();

	const handleItemClick = (code) => {
		onItemClick(code);
	};

	return (
		<Popover
			{...rest}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
		>
			<div className={classes.root}>
				<MenuList>
					<MenuItem onClick={() => handleItemClick('logout')}>Logout</MenuItem>
				</MenuList>
			</div>
		</Popover>
	);
}

AvatarMenuPopover.propTypes = {
	anchorEl: PropTypes.any,
	className: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	onItemClick: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired
};

export default AvatarMenuPopover;
