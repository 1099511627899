import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Typography,
	Grid,
	Button,
} from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';

const useStyles = makeStyles((theme) => ({
	root: {
	},
	header: {
		marginTop: theme.spacing(20)
	},
	summaryButton: {
		backgroundColor: theme.palette.common.white
	},
	barChartIcon: {
		marginRight: theme.spacing(1)
	},
	image: {
		width: '100%'
	}
}));

function Header({ className, ...rest }) {
	const classes = useStyles();
	const session = useSelector((state) => state.session);

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Grid
				alignItems="center"
				container
				justify="space-between"
				spacing={3}
			>
				<Grid
					className={classes.header}
					item
					md={5}
					xs={12}
				>
					<Typography
						component="h2"
						gutterBottom
						variant="overline"
					>
						Home
					</Typography>
					<Typography
						component="h1"
						gutterBottom
						variant="h3"
					>
						Good Morning,
						{' '}
						{session.user.first_name}
					</Typography>
					<Typography
						gutterBottom
						variant="subtitle1"
					>
						รายงานข้อมูลสรุปของเดือนนี้
					</Typography>
					{/* <Button
						className={classes.summaryButton}
						edge="start"
						variant="contained"
					>
						<BarChartIcon className={classes.barChartIcon} />
						View summary
					</Button> */}
				</Grid>
			</Grid>
		</div>
	);
}

Header.propTypes = {
	className: PropTypes.string
};

export default Header;
