import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'src/utils/moment';
import { makeStyles } from '@material-ui/styles';
import {
	Avatar,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	colors
} from '@material-ui/core';
import FastfoodRoundedIcon from '@material-ui/icons/FastfoodRounded';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import CodeIcon from '@material-ui/icons/Code';
import StoreIcon from '@material-ui/icons/Store';
import gradients from 'src/utils/gradients';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
	root: {},
	listItem: {
		'&:hover': {
			backgroundColor: theme.palette.background.default
		}
	},
	avatarBlue: {
		backgroundImage: gradients.blue
	},
	avatarGreen: {
		backgroundImage: gradients.green
	},
	avatarOrange: {
		backgroundImage: gradients.orange
	},
	avatarIndigo: {
		backgroundImage: gradients.indigo
	},
	arrowForwardIcon: {
		color: colors.green[400]
	}
}));

function NotificationList({
	notifications,
	onItemClick,
	className,
	...rest
}) {
	const classes = useStyles();
	const [items, setItems] = useState(notifications);

	const avatars = {
		order: (
			<Avatar className={classes.avatarBlue}>
				<FastfoodRoundedIcon />
			</Avatar>
		),
		user: (
			<Avatar className={classes.avatarOrange}>
				<PeopleIcon />
			</Avatar>
		),
		project: (
			<Avatar className={classes.avatarGreen}>
				<StoreIcon />
			</Avatar>
		),
		feature: (
			<Avatar className={classes.avatarIndigo}>
				<CodeIcon />
			</Avatar>
		)
	};

	const handleItemClick = (event, id) => {
		const index = items.findIndex((element) => element.id === id);
		if (items[index].is_read === 'N') {
			const newItems = { ...items };
			newItems[index].is_read = 'Y';
			setItems(newItems);
			// eslint-disable-next-line no-use-before-define
			doUpdateStatus(id);

			//	Close notification popup by call parent function
			onItemClick();
		}
	};

	//	Call api

	const doUpdateStatus = (id) => {
		// const params = `?id=${id}&is_read=Y`;
		// axios.patch(`${WebAPI.UPDATE_NOTIFICATION_STATUS}${params}`);
	};


	return (
		<List
			{...rest}
			className={clsx(classes.root, className)}
			disablePadding
		>
			{notifications.map((notification, i) => (
				<ListItem
					className={classes.listItem}
					component={RouterLink}
					divider={i < notifications.length - 1}
					key={notification.id}
					onClick={(event) => {
						handleItemClick(event, notification.id);
					}}
					to="#"
				>
					<ListItemAvatar>{avatars[notification.type]}</ListItemAvatar>
					<ListItemText
						primary={notification.title}
						primaryTypographyProps={{ variant: 'body1' }}
						secondary={moment(notification.created_date).locale('th').fromNow()}
					/>
					{notification.is_read === 'N' && <FiberManualRecordIcon className={classes.arrowForwardIcon} />}
				</ListItem>
			))}
		</List>
	);
}

NotificationList.propTypes = {
	className: PropTypes.string,
	notifications: PropTypes.array.isRequired,
	onItemClick: PropTypes.func
};

export default NotificationList;
