import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import OverviewView from './views/Overview';

export default [
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/overview" />
	},
	{
		path: '/auth',
		component: AuthLayout,
		routes: [
			{
				path: '/auth/login',
				exact: true,
				component: lazy(() => import('src/views/Login'))
			},
			{
				path: '/auth/privacy-policy',
				exact: true,
				component: lazy(() => import('src/views/PrivacyPolicy'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
	{
		path: '/errors',
		component: ErrorLayout,
		routes: [
			{
				path: '/errors/error-401',
				exact: true,
				component: lazy(() => import('src/views/Error401'))
			},
			{
				path: '/errors/error-404',
				exact: true,
				component: lazy(() => import('src/views/Error404'))
			},
			{
				path: '/errors/error-500',
				exact: true,
				component: lazy(() => import('src/views/Error500'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
	{
		path: '*',
		component: DashboardLayout,
		routes: [
			{
				path: '/overview',
				exact: true,
				component: OverviewView
			},
			{
				path: '/events',
				exact: true,
				component: lazy(() => import('src/views/Events/Calendar'))
			},
			{
				path: '/news',
				exact: true,
				component: lazy(() => import('src/views/News/NewsList'))
			},
			{
				path: '/news/:id',
				exact: true,
				component: lazy(() => import('src/views/News/NewsDetails'))
			},
			{
				path: '/website',
				exact: true,
				component: lazy(() => import('src/views/Website'))
			},
			{
				path: '/website/:tab',
				exact: true,
				component: lazy(() => import('src/views/Website'))
			},
			{
				path: '/content/video/categories',
				exact: true,
				component: lazy(() => import('src/views/Contents/VideoCategoryList'))
			},
			{
				path: '/content/video/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/VideoCategoryDetails'))
			},
			{
				path: '/content/article/categories',
				exact: true,
				component: lazy(() => import('src/views/Contents/ArticleCategoryList'))
			},
			{
				path: '/content/article/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/ArticleCategoryDetails'))
			},
			{
				path: '/content/articles',
				exact: true,
				component: lazy(() => import('src/views/Contents/ArticleList'))
			},
			{
				path: '/content/videos/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/VideoDetails'))
			},
			{
				path: '/content/videos',
				exact: true,
				component: lazy(() => import('src/views/Contents/VideoList'))
			},
			{
				path: '/content/articles/:id',
				exact: true,
				component: lazy(() => import('src/views/Contents/ArticleDetails'))
			},
			{
				path: '/product/categories',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Products/ProductCategoryList'))
			},
			{
				path: '/product/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Products/ProductCategoryDetails'))
			},
			{
				path: '/products',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Products/ProductList'))
			},
			{
				path: '/products/:id',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Products/ProductDetails'))
			},
			{
				path: '/shopping/website/',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Website'))
			},
			{
				path: '/shopping/website/:tab',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Website'))
			},
			{
				path: '/shopping/banners',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Banners/BannerList'))
			},
			{
				path: '/shopping/banners/:id',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Banners/BannerDetails'))
			},
			{
				path: '/shopping/articles',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Articles/ArticleList'))
			},
			{
				path: '/shopping/articles/:id',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Articles/ArticleDetails'))
			},
			{
				path: '/member/orders',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Member/OrderList'))
			},
			{
				path: '/member/orders/:id',
				exact: true,
				component: lazy(() => import('src/views/Shopping/Member/OrderDetails'))
			},
			{
				path: '/redeem/gifts',
				exact: true,
				component: lazy(() => import('src/views/Redeem/GiftList'))
			},
			{
				path: '/redeem/gifts/:id',
				exact: true,
				component: lazy(() => import('src/views/Redeem/GiftDetails'))
			},
			{
				path: '/redeem/histories',
				exact: true,
				component: lazy(() => import('src/views/Redeem/HistoryList'))
			},
			{
				path: '/redeem/settings',
				exact: true,
				component: lazy(() => import('src/views/Redeem/Settings'))
			},
			{
				path: '/redeem/settings/:tab',
				exact: true,
				component: lazy(() => import('src/views/Redeem/Settings'))
			},
			{
				path: '/guest/settings',
				exact: true,
				component: lazy(() => import('src/views/Guests/Settings'))
			},
			{
				path: '/guest/settings/:tab',
				exact: true,
				component: lazy(() => import('src/views/Guests/Settings'))
			},
			{
				path: '/guests',
				exact: true,
				component: lazy(() => import('src/views/Guests/GuestList'))
			},
			{
				path: '/guests/orders',
				exact: true,
				component: lazy(() => import('src/views/Guests/OrderList'))
			},
			{
				path: '/guests/orders/:id',
				exact: true,
				component: lazy(() => import('src/views/Guests/OrderDetails'))
			},
			{
				path: '/guest/:id',
				exact: true,
				component: lazy(() => import('src/views/Guests/GuestProfile'))
			},
			{
				path: '/guest/:id/:tab',
				exact: true,
				component: lazy(() => import('src/views/Guests/GuestProfile'))
			},
			{
				path: '/member/groups',
				exact: true,
				component: lazy(() => import('src/views/Members/MemberGroupList'))
			},
			{
				path: '/member/groups/:id',
				exact: true,
				component: lazy(() => import('src/views/Members/MemberGroupDetails'))
			},
			{
				path: '/members',
				exact: true,
				component: lazy(() => import('src/views/Members/MemberList'))
			},
			{
				path: '/members/network',
				exact: true,
				component: lazy(() => import('src/views/Members/MemberNetwork'))
			},
			{
				path: '/member/qualified',
				exact: true,
				component: lazy(() => import('src/views/Members/QualifiedList'))
			},
			{
				path: '/member/qualified/:id',
				exact: true,
				component: lazy(() => import('src/views/Members/QualifiedDetails'))
			},
			{
				path: '/member/quota',
				exact: true,
				component: lazy(() => import('src/views/Members/QuotaList'))
			},
			{
				path: '/member/quota/:id',
				exact: true,
				component: lazy(() => import('src/views/Members/QuotaDetails'))
			},
			{
				path: '/member/settings',
				exact: true,
				component: lazy(() => import('src/views/Members/Settings'))
			},
			{
				path: '/member/settings/:tab',
				exact: true,
				component: lazy(() => import('src/views/Members/Settings'))
			},
			{
				path: '/member/:id',
				exact: true,
				component: lazy(() => import('src/views/Members/MemberProfile'))
			},
			{
				path: '/member/:id/:tab',
				exact: true,
				component: lazy(() => import('src/views/Members/MemberProfile'))
			},
			{
				path: '/kpi/achievements',
				exact: true,
				component: lazy(() => import('src/views/Kpi/AchievementList'))
			},

			{
				path: '/kpi/achievements/:id',
				exact: true,
				component: lazy(() => import('src/views/Kpi/AchievementDetails'))
			},
			{
				path: '/kpi/leaders',
				exact: true,
				component: lazy(() => import('src/views/Kpi/LeaderList'))
			},
			{
				path: '/email/categories',
				exact: true,
				component: lazy(() => import('src/views/Tools/Email/CategoryList'))
			},
			{
				path: '/email/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Tools/Email/CategoryDetails'))
			},
			{
				path: '/email/templates',
				exact: true,
				component: lazy(() => import('src/views/Tools/Email/TemplateList'))
			},
			{
				path: '/email/templates/:id',
				exact: true,
				component: lazy(() => import('src/views/Tools/Email/TemplateDetails'))
			},
			{
				path: '/email/settings',
				exact: true,
				component: lazy(() => import('src/views/Tools/Email/Settings'))
			},
			{
				path: '/liff/categories',
				exact: true,
				component: lazy(() => import('src/views/Tools/Liff/CategoryList'))
			},
			{
				path: '/liff/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Tools/Liff/CategoryDetails'))
			},
			{
				path: '/liff/contents',
				exact: true,
				component: lazy(() => import('src/views/Tools/Liff/ContentList'))
			},
			{
				path: '/liff/contents/:id',
				exact: true,
				component: lazy(() => import('src/views/Tools/Liff/ContentDetails'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	}
];
