import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	Divider,
	Typography,
	Grid,
	colors
} from '@material-ui/core';
import Label from 'src/components/Label';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const useStyles = makeStyles((theme) => ({
	root: {},
	content: {
		padding: 0
	},
	item: {
		padding: theme.spacing(3),
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			'&:not(:last-of-type)': {
				borderRight: `1px solid ${theme.palette.divider}`
			}
		},
		[theme.breakpoints.down('sm')]: {
			'&:not(:last-of-type)': {
				borderBottom: `1px solid ${theme.palette.divider}`
			}
		}
	},
	titleWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	label: {
		marginLeft: theme.spacing(1)
	},
	overline: {
		marginTop: theme.spacing(1)
	}
}));

function Statistics({ className, ...rest }) {
	const classes = useStyles();
	const [statistics, setStatistics] = useState(null);

	useEffect(() => {
		let mounted = true;

		const fetchStatistics = () => {
			axios.get(WebAPI.SUMMARY_STATS).then((response) => {
				if (mounted) {
					const { data } = response.data;
					// data.paid_percentage = ((data.paid_orders * 100) / data.leads).toFixed(0);
					setStatistics(data);
				}
			});
		};

		fetchStatistics();

		return () => {
			mounted = false;
		};
	}, []);

	if (!statistics) {
		return null;
	}

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Grid
				alignItems="center"
				container
				justify="space-between"
			>
				<Grid
					className={classes.item}
					item
					md={4}
					sm={6}
					xs={12}
				>
					<Typography variant="h2">
						{statistics.members}
					</Typography>
					<Typography
						className={classes.overline}
						variant="overline"
					>
						ล็อกอิน (คน)
					</Typography>
				</Grid>
				<Grid
					className={classes.item}
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className={classes.titleWrapper}>
						<Typography variant="h2">
							{statistics.orders}
						</Typography>
						{/* <Label
							className={classes.label}
							color={colors.purple[500]}
							variant="outlined"
						>
							{`${statistics.paid_percentage}%`}
						</Label> */}
					</div>
					<Typography
						className={classes.overline}
						variant="overline"
					>
						คำสั่งซื้อ (รายการ)
					</Typography>
				</Grid>
				<Grid
					className={classes.item}
					item
					md={4}
					sm={6}
					xs={12}
				>
					<Typography variant="h2">{statistics.liff}</Typography>
					<Typography
						className={classes.overline}
						variant="overline"
					>
						แชร์ Line Content (ครั้ง)
					</Typography>
				</Grid>
			</Grid>
			<Divider />
			<Grid
				alignItems="center"
				container
				justify="center"
			>
				<Grid
					className={classes.item}
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className={classes.titleWrapper}>
						<Typography
							component="span"
							variant="h2"
						>
							{statistics.pages}
						</Typography>
						{/* <Label
							className={classes.label}
							color={colors.teal.A200}
						>
							NEW
						</Label> */}
					</div>
					<Typography
						className={classes.overline}
						variant="overline"
					>
						แชร์เซลเพจ (ครั้ง)
					</Typography>
				</Grid>
				<Grid
					className={classes.item}
					item
					md={4}
					sm={6}
					xs={12}
				>
					<div className={classes.titleWrapper}>
						<Typography
							component="span"
							variant="h2"
						>
							{statistics.contents}
						</Typography>
						{/* <Label
							className={classes.label}
							color={colors.teal.A200}
						>
							NEW
						</Label> */}
					</div>
					<Typography
						className={classes.overline}
						variant="overline"
					>
						การดูสื่อ (นาที)
					</Typography>
				</Grid>
			</Grid>
		</Card>
	);
}

Statistics.propTypes = {
	className: PropTypes.string
};

export default Statistics;
