import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardContent,
	Grid,
} from '@material-ui/core';
import {
	Skeleton
} from '@material-ui/lab';

import LiffChart from './LiffChart';
import PageChart from './PageChart';

const useStyles = makeStyles(() => ({
	root: {
	},
}));

function Loading({ className, ...rest }) {
	const classes = useStyles();

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Grid
				alignItems="flex-start"
				container
				justify="space-between"
				spacing={3}
			>
				<Grid
					item
					md={6}
					xs={12}
				>
					<LiffChart data={null} />
				</Grid>
				<Grid
					item
					md={6}
					xs={12}
				>
					<PageChart data={null} />
				</Grid>
			</Grid>
		</div>
	);
}

Loading.propTypes = {
	className: PropTypes.string
};

export default Loading;
