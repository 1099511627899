import * as actionTypes from 'src/actions';

const initialState = {
	/* loggedIn: true,
	user: {
		first_name: 'Shen',
		last_name: 'Zhi',
		email: 'demo@devias.io',
		avatar: '/images/avatars/avatar_11.png',
		bio: 'Brain Director',
		role: 'ADMIN' // ['GUEST', 'USER', 'ADMIN']
	} */
	loggedIn: false,
	user: {
		role: 'GUEST' // ['GUEST', 'USER', 'ADMIN']
	},
	project: {},
	website: {},
	nav_menu: []
};

const sessionReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SESSION_LOGIN: {
			return {
				...initialState,
				loggedIn: true,
				user: action.payload.user,
				project: action.payload.project
			};
		}

		case actionTypes.SESSION_LOGOUT: {
			return {
				...state,
				loggedIn: false,
				user: {
					role: 'GUEST'
				}
			};
		}

		case actionTypes.REFRESH_TOKEN: {
			const newState = { ...state };
			newState.user.access_token = action.payload.access_token;
			return {
				...newState,
			};
		}

		case actionTypes.UPDATE_NAV_MENU: {
			const newState = { ...state };
			const { payload } = action;
			newState.nav_menu = payload;

			return {
				...newState
			};
		}

		case actionTypes.UPDATE_PROFILE: {
			const newState = { ...state };
			const { payload } = action;
			newState.user.firstname = payload.firstname;
			newState.user.lastname = payload.lastname;
			if (payload.avatar) {
				newState.user.avatar = payload.avatar;
			}

			return {
				...newState
			};
		}

		case actionTypes.UPDATE_WEBSITE: {
			const newState = { ...state };
			const { payload } = action;
			newState.website = payload;

			return {
				...newState
			};
		}

		default: {
			return state;
		}
	}
};

export default sessionReducer;
