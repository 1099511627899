import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Grid
} from '@material-ui/core';

import Loading from './Loading';
import LeadChart from './LeadChart';
import ApprovedLeadCard from './ApprovedLeadCard';
import AllLeadCard from './AllLeadCard';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const useStyles = makeStyles(() => ({
	root: {
	},
}));

function Lead({ className, ...rest }) {
	const classes = useStyles();

	const [object, setObject] = useState(null);

	//	Functions

	useEffect(() => {
		let mounted = true;

		const fetchObjects = () => {
			axios.get(WebAPI.OVERVIEW_LEADS)
				.then((response) => {
					if (mounted) {
						const { data } = response;
						setObject(data.data);
					}
				});
		};
		fetchObjects();

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			{!object && (
				<Loading />
			)}
			{object && (
				<Grid
					alignItems="flex-start"
					container
					justify="space-between"
					spacing={3}
				>
					<Grid
						item
						md={4}
						xs={12}
					>
						<AllLeadCard count={object.all_count} />
						<ApprovedLeadCard count={object.month_count} style={{ marginTop: 24 }} />
					</Grid>
					<Grid
						item
						md={8}
						xs={12}
					>
						<LeadChart data={object.members} />
					</Grid>
				</Grid>
			)}
		</div>
	);
}

Lead.propTypes = {
	className: PropTypes.string
};

export default Lead;
